import { FC } from 'react';
import { AppFooter } from '../footer/appFooter';

type ContentProps = { children: JSX.Element };

export const Content: FC<ContentProps> = (props: ContentProps) => {
    return (
        <div
            style={{
                minHeight: 'calc(100vh - 64px)',
                display: 'flex',
                flexDirection: 'column',
                padding: '5px',
            }}
        >
            {props.children}
            <AppFooter />
        </div>
    );
};
