import { Button, Modal, Search, Tag } from '@appkit4/react-components';
import { ItemDataType } from '@appkit4/react-components/esm/search/Search';
import { useState } from 'react';
import useApi from '../../../../../../api';
import { ISearchUsersResponse } from '../../../../../../models/searchUsersResponse';
import styles from './sendCopyModal.module.scss';

type SendCopyModalProps = {
    reportName: string;
    onCancel: () => void;
    onSend: (recipientsIds: number[]) => void;
};

type SelectedUser = {
    id: number;
    email: string;
};

export const SendCopyModal = (props: SendCopyModalProps) => {
    const { searchUsers } = useApi();
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>();
    const [searchValue, setSearchValue] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);
    const [data, setData] = useState<ItemDataType[]>([]);

    const handleSelect = (
        value: string,
        item: ItemDataType,
        event: React.SyntheticEvent
    ) => {
        const users = selectedUsers;
        const user: SelectedUser = {
            id: item.value as number,
            email: item.label as string,
        };
        users.push(user);
        setSelectedUsers(users);
        setData(data.filter((i) => i.value !== item.value));
        setSearchValue('');
    };

    const handleUnselect = (userId: number) => {
        setSelectedUsers(
            selectedUsers.filter((selected) => selected.id !== userId)
        );
        setSearchValue('');
    };

    const handleSearch = (value: string, event: React.SyntheticEvent) => {
        // prevent call search when selecting value by clicking it in Appkit Search component
        if (event.type === 'click') return;

        setSearchValue(value);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        setTimeoutId(
            setTimeout(() => {
                if (value.length > 2) {
                    search(value);
                }
            }, 300)
        );
    };

    const search = async (searchValue: string) => {
        const response = await searchUsers(searchValue);
        const users: ISearchUsersResponse[] = await response.json();
        const filtered = users.filter(
            (user) => selectedUsers.find((s) => s.id === user.id) == null
        );
        const searchData = filtered.map((user) => {
            return { value: user.id, label: user.email };
        });
        setData(searchData);
        setSearchValue(searchValue);
    };

    const handleSend = () => {
        if (selectedUsers.length === 0) {
            return;
        }
        props.onSend(selectedUsers.map((user) => user.id));
    };

    const selectedUsersContent =
        selectedUsers.length === 0 ? (
            <span>No emails selected.</span>
        ) : (
            selectedUsers.map((user) => (
                <Tag key={user.id} onClose={() => handleUnselect(user.id)}>
                    {user.email}
                </Tag>
            ))
        );

    return (
        <Modal
            className={styles.sendCopyModal}
            visible={true}
            maskCloseable={false}
            ariaLabel={'The modal for sending copy of the report'}
            title={'Send a copy of this report'}
            footer={
                <>
                    <Button
                        aria-label="Cancel"
                        onClick={props.onCancel}
                        kind="secondary"
                    >
                        Cancel
                    </Button>
                    <Button aria-label="Send a copy" onClick={handleSend}>
                        Send a copy
                    </Button>
                </>
            }
            footerStyle={{
                paddingTop: '8px',
                marginTop: '-8px',
            }}
        >
            <div>
                Enter the email address of the person you&apos;d like to send a
                copy of <strong>{props.reportName}</strong> report to below.
            </div>
            <div className={styles.selectedEmailsContainer}>
                {selectedUsersContent}
            </div>
            <Search
                ariaLabel="Email address"
                placeholder="Email address"
                data={data}
                searchType="secondary"
                onSelect={handleSelect}
                searchValue={searchValue}
                onClear={() => setSearchValue('')}
                onChange={handleSearch}
            />
        </Modal>
    );
};
