export const TcContent = () => {
    return (
        <>
            <p className="ql-align-justify">Last updated: 1st March 2021</p>
            <p className="ql-align-justify">
                You have been given access by a PwC firm (also referred to as
                “us”, or “we”) to PwC Learning Lab (“this technology tool”).
                These terms form an agreement between you and that PwC firm;
                please read them carefully.
            </p>
            <p className="ql-align-justify">
                By using this technology tool, you agree to these terms. If you
                cannot comply with these terms, then you must cease using this
                technology tool. These terms apply when you access the
                technology tool using a web browser or through the PwC Learning
                Lab app on your mobile device (the “app”).
            </p>
            <p className="ql-align-justify">
                We may amend these terms from time to time. The amended terms
                will either be notified to you, or be available for you to view
                on (or when accessing) the technology tool.
            </p>
            <p className="ql-align-justify">
                <br />
            </p>
            <ol>
                <li className="ql-align-justify">
                    <strong>
                        <em>Use</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Permitted use. You may only use this technology
                            tool, the content on it, and the outputs from it,
                            for their intended purpose and subject to these
                            terms save as otherwise agreed in writing. You may
                            print off one copy, and may download extracts, of
                            any page(s) or screenshots from the technology tool
                            for your own purposes
                        </li>
                        <li className="ql-align-justify">
                            Restrictions on use. You may not:
                            <ol>
                                <li className="ql-align-justify">
                                    act or omit to act in any way that puts you
                                    or any entity you work for in breach of any
                                    agreement with any PwC firm, or any
                                    applicable law or regulation;
                                </li>
                                <li className="ql-align-justify">
                                    make this technology tool, the contents or
                                    the outputs available to any person, other
                                    than as permitted by us;
                                </li>
                                <li className="ql-align-justify">
                                    copy, modify, disassemble, work around,
                                    by-pass, reverse engineer or otherwise
                                    change this technology tool, the content
                                    within it or its underlying data, systems or
                                    software, or attempt to do so;
                                </li>
                                <li className="ql-align-justify">
                                    copy or use the contents or outputs, other
                                    than as expressly permitted by us;
                                </li>
                                <li className="ql-align-justify">
                                    engage in or encourage unlawful, invasive,
                                    infringing, defamatory or fraudulent
                                    activity (including to violate, or encourage
                                    the violation of, the legal rights of
                                    others); impersonate another person or
                                    otherwise misrepresent your identity or
                                    other information about you;
                                </li>
                                <li className="ql-align-justify">
                                    remove or alter any identification,
                                    proprietary, copyright or other notices on
                                    the technology tool or the contents or
                                    outputs;
                                </li>
                                <li className="ql-align-justify">
                                    access or use the technology tool on any
                                    publicly available equipment;
                                </li>
                                <li className="ql-align-justify">
                                    bypass or attempt to bypass any of the
                                    access or security controls or otherwise
                                    weaken or threaten the security of the
                                    technology tool; or
                                </li>
                                <li className="ql-align-justify">
                                    use any part of the content on or in the
                                    technology tool for commercial purposes
                                    without first obtaining a licence to do so
                                    from us. We may charge a fee for the
                                    licence.
                                </li>
                            </ol>
                        </li>
                        <li className="ql-align-justify">
                            Access. You agree to:
                            <ol>
                                <li className="ql-align-justify">
                                    maintain your access credentials in strict
                                    confidence and not share them with anyone
                                    else;
                                </li>
                                <li className="ql-align-justify">
                                    immediately notify us by email if anyone
                                    else obtains your access credentials so that
                                    we can disable them and provide you with new
                                    credentials; and
                                </li>
                                <li className="ql-align-justify">
                                    tell your PwC contact immediately if you
                                    lose your access credentials or know or
                                    suspect someone else may know or have access
                                    to them.
                                </li>
                            </ol>
                        </li>
                        <li className="ql-align-justify">
                            Incorrect access. You agree to notify us
                            immediately, and then exit the technology tool, if
                            you access content or outputs on it that are not
                            intended for you.
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>No liability to you</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Use is at your own risk. As between you and any PwC
                            firm
                            <ol>
                                <li className="ql-align-justify">
                                    this technology tool, the content and
                                    outputs are provided on an ‘as-is’ basis;
                                </li>
                                <li className="ql-align-justify">
                                    to the extent permitted by law, no
                                    warranties or other provisions are implied
                                    into these terms. We do not warrant that the
                                    operation of the technology tool will be
                                    uninterrupted or error-free, or that the
                                    technology tool, the content and outputs
                                    will be fit for any particular purpose or be
                                    free of errors or defects;
                                </li>
                                <li className="ql-align-justify">
                                    to the extent permitted by law, no PwC firm
                                    accepts any liability, responsibility or
                                    duty of care to you for the technology tool,
                                    the content or outputs, or for any
                                    consequences of you or anyone acting or
                                    refraining to act in reliance on them, or
                                    for any decision based on them; and
                                </li>
                                <li className="ql-align-justify">
                                    nothing in these terms excludes our
                                    liability for fraud, or for anything else
                                    that cannot be law be limited.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>Suspension or termination and changes</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Ending your access. We may immediately suspend or
                            terminate access to the technology tool if you use
                            it other than as permitted by us, or if we consider
                            that your use of the product could cause a detriment
                            to us or others, or for any other reason we require
                            (e.g. maintenance, or to make any changes or
                            improvements)
                        </li>
                        <li className="ql-align-justify">
                            Changes to this technology tool. We may change the
                            technology tool without notice, at our sole
                            discretion, including the performance, functionality
                            and availability. Such changes will reflect changes
                            to PwC Learning Lab, our users’ needs and our
                            business priorities.
                        </li>
                        <li className="ql-align-justify">
                            Use of mobile applications. Where the technology
                            tool is downloaded and used on your mobile device,
                            additional terms and conditions may be imposed by
                            the online store from which you obtain the app, and
                            you agree to be bound by them.
                        </li>
                        <li className="ql-align-justify">
                            We may suspend or withdraw the technology tool. We
                            do not guarantee that the technology tool, or any
                            content on or in it, will always be available or be
                            uninterrupted. We may suspend or withdraw or
                            restrict the availability of all or any part of the
                            technology tool for business and operational
                            reasons. We will try to give you reasonable notice
                            of any suspension or withdrawal. You are also
                            responsible for ensuring that all persons who access
                            the technology tool through your internet connection
                            or mobile device are aware of these terms of use and
                            other applicable terms and conditions, and that they
                            comply with them.
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>Your inputs</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Your inputs. To the extent that you make or upload
                            inputs of any sort to the technology tool (which may
                            include comments, information, materials, data, or
                            anything else), you grant: i) us and our suppliers
                            the right to host your inputs on the technology tool
                            or any other system we deem appropriate, and use it
                            to produce the outputs or for any other lawful
                            business purpose (which may include technical
                            support and maintenance); and ii) us and anyone we
                            lawfully permit to access the technology tool the
                            ability to view, access and download those inputs.
                            You agree that you have all necessary rights in your
                            inputs to grant the rights set out in this section.
                            No payment, royalty or other consideration
                            whatsoever will be payable by us in connection with
                            our use of such inputs. You agree to reimburse us in
                            full to the extent we incur any liability as a
                            result of your non-compliance with this section.
                        </li>
                        <li className="ql-align-justify">
                            Moderation - We may, in our discretion, moderate
                            your inputs and other content (including making
                            content unavailable), for example where the
                            technology tool contains a discussion board or other
                            open forum. However, we are under no obligation to
                            oversee, monitor or moderate any content. The views
                            expressed by users of the technology tool do not
                            necessarily represent our views or values, nor are
                            they supported or endorsed by us or any PwC firm.
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>Ownership</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Intellectual property rights. We and our licensors
                            own this technology tool, the content, and the
                            outputs. You have a limited, non-transferable,
                            non-sublicensable licence to use them in accordance
                            with these terms only. (Our licensors may include an
                            entity you work for).
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>Open Source</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Open source software. Portions of the technology
                            tool may use open source components, a list of which
                            may be available on the technology tool or is
                            otherwise available from us on request. The source
                            code and open source licensing terms applicable to
                            each open source component can be obtained either by
                            clicking on the related link, or from us on request.
                        </li>
                        <li className="ql-align-justify">
                            Open source licence terms prevail. In the event of a
                            conflict between the terms of any open source
                            license and these terms, the open source license
                            terms shall prevail.
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>General</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            Disputes.
                            <ol>
                                <li className="ql-align-justify">
                                    If a dispute arises, you and we will attempt
                                    to resolve it by discussion, negotiation and
                                    mediation before commencing legal
                                    proceedings.
                                </li>
                                <li className="ql-align-justify">
                                    These terms and any dispute arising from
                                    them, whether contractual or
                                    non-contractual, will be governed by the law
                                    of the territory in which we operate, and be
                                    subject to the exclusive jurisdiction of the
                                    courts of that territory.
                                </li>
                                <li className="ql-align-justify">
                                    Any claims must be brought no later than 2
                                    years after the date the claimant should
                                    have been aware of the potential claim and,
                                    in any event, no later than 4 years after
                                    any alleged breach.
                                </li>
                            </ol>
                        </li>
                        <li className="ql-align-justify">
                            Matters beyond reasonable control. We will not be
                            liable to you if we fail to meet our obligations due
                            to matters beyond our reasonable control.
                        </li>
                        <li className="ql-align-justify">
                            Rights of third parties. A person who is not a party
                            to these terms has no rights to enforce them.
                        </li>
                        <li className="ql-align-justify">
                            Entire agreement. These terms form the entire
                            agreement between you and us in relation to the
                            technology tool, the content and the outputs. These
                            terms replace any earlier agreements,
                            representations or discussions.
                        </li>
                        <li className="ql-align-justify">
                            Your personal data. All information, rights and
                            obligations concerning personal data in relation to
                            the technology tool is contained in its privacy
                            statement.
                        </li>
                        <li className="ql-align-justify">
                            System of record. The technology tool is not, and is
                            not designed to operate as, a system of record. You
                            are solely responsible for keeping any inputs added
                            to the technology tool, anything you wish to
                            download from the technology tool, and any copies
                            required and you must do so in accordance with these
                            terms or as otherwise agreed in writing with us.
                        </li>
                        <li className="ql-align-justify">
                            Non-reliance. The content on or in the technology
                            tool is provided for general information only. It is
                            not intended to amount to advice on which you should
                            rely. You must obtain professional or specialist
                            advice before taking, or refraining from, any action
                            on the basis of the content on or in the technology
                            tool. Although we make reasonable efforts to update
                            the information presented by the technology tool, we
                            make no representations, warranties or guarantees,
                            whether express or implied, that the content on or
                            in the technology tool is accurate, complete or up
                            to date.
                        </li>
                        <li className="ql-align-justify">
                            Websites we link to. Where the technology tool
                            contains links to other sites and resources provided
                            by third parties, these links are provided for your
                            information only. Such links should not be
                            interpreted as approval by us of those linked
                            websites or information you may obtain from them. We
                            have no control over the contents of those sites or
                            resources.
                        </li>
                        <li className="ql-align-justify">
                            Configuration. You are responsible for configuring
                            your information technology, computer programmes,
                            devices and platforms to access the technology tool.
                            You should use your own virus protection software.
                        </li>
                        <li className="ql-align-justify">
                            Linking to the technology tool. Where we agree with
                            you in writing, you may link to the technology tool
                            home page, provided you do so in a way that is fair
                            and legal and does not damage our reputation or take
                            advantage of it. You must not establish a link in
                            such a way as to suggest any form of association,
                            approval or endorsement on our part where none
                            exists. You must not establish a link to the
                            technology tool in any website that is not owned by
                            you. The technology tool must not be framed on any
                            other site, nor may you create a link to any part of
                            the technology tool other than the home page. We
                            reserve the right to withdraw linking permission
                            without notice. If you wish to link to or make any
                            use of content on the technology tool, please email
                            us at learninglabsupport@pwc.com.
                        </li>
                    </ol>
                </li>
                <li className="ql-align-justify">
                    <strong>
                        <em>Definitions</em>
                    </strong>
                    <ol>
                        <li className="ql-align-justify">
                            The following words and expressions in these terms
                            have the following meanings:
                            <ol>
                                <li className="ql-align-justify">
                                    access credentials - the username and
                                    password or other access details that you
                                    may use to access the technology tool
                                </li>
                                <li className="ql-align-justify">
                                    content - the information, data, material
                                    and any other content on the technology
                                    tool, other than the outputs
                                </li>
                                <li className="ql-align-justify">
                                    outputs - any visualisation, graphic,
                                    calculation, result or other output produced
                                    by the functionality of the technology tool
                                </li>
                                <li className="ql-align-justify">
                                    these terms - means the agreement formed by
                                    this “terms of use” document
                                </li>
                                <li className="ql-align-justify">
                                    PwC firm - a member firm in the PwC network,
                                    each of which is a separate legal entity.
                                    For further details, please see
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
            <p>
                For further details, please see&nbsp;
                <a
                    aria-label="www.pwc.com/structure"
                    style={{ color: '#1155cc' }}
                    href="https://www.pwc.com/structure"
                    target="_blank"
                    rel="noreferrer"
                >
                    www.pwc.com&#x2F;structure
                </a>
            </p>
        </>
    );
};
