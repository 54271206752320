import { Button, Loading, Modal } from '@appkit4/react-components';
import { useState } from 'react';

type ExportModalProps = {
    reportName: string;
    onCancel: () => void;
    onExport: () => void;
};

export const ExportModal = (props: ExportModalProps) => {
    const [loading, setLoading] = useState(false);

    const questionContent = (
        <p>
            Are you sure you want to export <strong>{props.reportName}</strong>{' '}
            to a CSV?
        </p>
    );
    const loadingContent = (
        <Loading
            role="modal"
            aria-modal="true"
            aria-label="loading"
            loadingType="circular"
            indeterminate={true}
        />
    );
    const bodyContent = loading ? loadingContent : questionContent;

    const modalTitle = loading ? 'Exporting report...' : 'Export this report';

    const handleExport = () => {
        setLoading(true);
        props.onExport();
    };

    return (
        <Modal
            visible={true}
            ariaLabel={'The modal for exporting the report to CSV'}
            title={modalTitle}
            bodyStyle={{ minHeight: '50px' }}
            footer={
                !loading && (
                    <>
                        <Button
                            aria-label="Cancel"
                            onClick={props.onCancel}
                            kind="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            aria-label="Export report"
                            onClick={handleExport}
                        >
                            Export report
                        </Button>
                    </>
                )
            }
            footerStyle={{
                paddingTop: '8px',
                marginTop: '-8px',
            }}
            maskCloseable={!loading}
            closeOnPressEscape={!loading}
            onCancel={props.onCancel}
        >
            {bodyContent}
        </Modal>
    );
};

export default ExportModal;
