import { Combobox, Search } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useEffect, useState } from 'react';
import useApi from '../../../../api';
import { IUpdateReportChannelsRequest } from '../../../../api/models/updateReportChannelsRequest';
import { IUpdateReportColumnsRequest } from '../../../../api/models/updateReportColumnsRequest';
import useGroupedChannels from '../../../../hooks/useGroupedChannels';
import {
    ButtonDropdown,
    IButtonDropdownValue,
} from '../../../buttonDropdown/buttonDropdown';
import { IReportColumn } from '../../models/reportColumn';
import { ReportItemsFilter } from '../../models/reportItemsFilter';
import styles from './reportTableActions.module.scss';

type ReportDataItem = {
    filters: ReportItemsFilter;
    setFilters: (newFilers: ReportItemsFilter) => void;
    reportId: number;
    columns: Array<IReportColumn>;
    setNewColumns: (newColumns: IReportColumn[]) => void;
};
export const ReportTableActions = (props: ReportDataItem) => {
    const { updateReportChannels, updateReportColumns } = useApi();
    const { groupedChannels } = useGroupedChannels();

    const [searchValue, setSearchValue] = useState('');
    const [channelValue, setChannelValue] = useState<string[]>(
        props.filters.channels
    );

    const handleManageColumns = async (selectedCodeNames: string[]) => {
        const request: IUpdateReportColumnsRequest = {
            columns: props.columns
                .sort((c1, c2) => c1.order - c2.order)
                .map((c: IReportColumn) => {
                    return {
                        codeName: c.codeName,
                        isVisible: selectedCodeNames.includes(c.codeName),
                    };
                }),
        };
        const response = await updateReportColumns(request, props.reportId);
        const updatedColumns: IReportColumn[] = await response.json();
        props.setNewColumns(updatedColumns);
    };

    const handleSelectedChannelsChange = async (
        selectedChannels: SelectValue
    ) => {
        const request: IUpdateReportChannelsRequest = {
            channelNames: selectedChannels as string[],
        };
        const response = await updateReportChannels(request, props.reportId);
        const updatedChannels: string[] = await response.json();

        setChannelValue(updatedChannels);
        props.setFilters({ ...props.filters, channels: updatedChannels });
    };

    const manageColumnsData: IButtonDropdownValue[] = props.columns.map(
        (c: IReportColumn) => {
            return {
                value: c.codeName,
                name: c.friendlyName,
                isChecked: c.isVisible,
            };
        }
    );

    const channelDropdownData = [
        {
            label: 'Terrestrial',
            type: 'group',
            children:
                groupedChannels?.defaultChannels.map((channel) => ({
                    value: channel,
                    label: channel,
                })) ?? [],
        },
        {
            label: 'All channels',
            type: 'group',
            children:
                groupedChannels?.otherChannels.map((channel) => ({
                    value: channel,
                    label: channel,
                })) ?? [],
        },
    ];

    useEffect(() => {
        if (props.filters.searchValue === searchValue) {
            return;
        }

        const debounceTimeout = setTimeout(() => {
            props.setFilters({
                ...props.filters,
                searchValue: searchValue.length > 2 ? searchValue : '',
            });
        }, 300);
        return () => clearTimeout(debounceTimeout);
    }, [searchValue, props]);

    return (
        <div className={styles.container}>
            <ButtonDropdown
                buttonKind="secondary"
                buttonText="Manage columns"
                ariaLabel="Manage columns"
                dropdownValues={manageColumnsData}
                onValueChange={handleManageColumns}
            />
            <div className={styles.tableButtonsBox}>
                <Combobox
                    aria-label="Channel filter"
                    placeholder="All channels"
                    className={styles.selectButton}
                    data={channelDropdownData}
                    multiple
                    showSelectAll={false}
                    dropdownMatchWidth={true}
                    onSelect={handleSelectedChannelsChange}
                    value={channelValue}
                />
                <Search
                    ariaLabel="Search"
                    placeholder="Search"
                    className={styles.searchButton}
                    onChange={setSearchValue}
                    searchValue={searchValue}
                />
            </div>
        </div>
    );
};
