export const useBlobDownloader = () => {
    const downloadBlob = (blob: Blob, filename: string) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.hidden = true;
        link.target = '_blank';
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
    };

    return { downloadBlob };
};
