export interface IReportDateType {
    fromHour: number;
    toHour: number;
    isAllDay: boolean;
}

export interface IDynamicReportDate extends IReportDateType {
    dynamicReportPeriodTypeId: number;
    areNotificationsOn: boolean;
}

export interface IStaticReportDate extends IReportDateType {
    startDate: Date;
    endDate: Date;
}

export const isDynamicReportDateType = (reportDateType: any) => {
    return (
        isReportDateType(reportDateType) &&
        typeof reportDateType.dynamicReportPeriodTypeId === 'number' &&
        typeof reportDateType.areNotificationsOn === 'boolean'
    );
};

export const isStaticReportDateType = (reportDateType: any) => {
    return (
        isReportDateType(reportDateType) &&
        typeof reportDateType.startDate === 'string' &&
        typeof reportDateType.endDate === 'string'
    );
};

const isReportDateType = (reportDateType: any) => {
    return (
        reportDateType &&
        typeof reportDateType.fromHour === 'number' &&
        typeof reportDateType.toHour === 'number' &&
        typeof reportDateType.isAllDay === 'boolean'
    );
};
