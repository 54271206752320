import { useCallback } from 'react';
import { useAuth } from 'react-oidc-context';
import {
    AcceptedCookiesPolicyPath,
    AcceptedTCPath,
    ApiUri,
    AuthorizationContextPath,
} from '../Constants';
import { ICustomiseReportRequest } from './models/customiseReportRequest';
import { IGetReportItemsRequest } from './models/getReportItemsRequest';
import { IGetReportsRequest } from './models/getReportsRequest';
import { IShareReportRequest } from './models/shareReportRequest';
import { IUpdateDateFiltersRequest } from './models/updateDateFiltersRequest';
import { IUpdateReportChannelsRequest } from './models/updateReportChannelsRequest';
import { IUpdateReportColumnsRequest } from './models/updateReportColumnsRequest';
import { IUpdateReportOrderRequest } from './models/updateReportOrderRequest';

export default function useApi() {
    const auth = useAuth();

    const isAuthenticated = () => {
        return auth && auth.isAuthenticated;
    };

    const getAcceptedTC = useCallback(() => {
        return fetch(`${ApiUri}${AcceptedTCPath}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const getAcceptedCookies = useCallback(() => {
        return fetch(`${ApiUri}${AcceptedCookiesPolicyPath}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const getAuthorizationContext = useCallback(() => {
        return fetch(`${ApiUri}${AuthorizationContextPath}`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const updateAcceptedTCVersion = useCallback(
        (tcVersionAccepted: number | null) => {
            return fetch(`${ApiUri}${AcceptedTCPath}/${tcVersionAccepted}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const acceptCookies = useCallback(() => {
        return fetch(`${ApiUri}${AcceptedCookiesPolicyPath}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const getReports = useCallback(
        (request: IGetReportsRequest) => {
            return fetch(`${ApiUri}/Reports`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const getReportById = useCallback(
        (id: number) => {
            return fetch(`${ApiUri}/Reports/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const getReportItemsById = useCallback(
        (request: IGetReportItemsRequest) => {
            return fetch(`${ApiUri}/ReportItems`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateReport = useCallback(
        (request: ICustomiseReportRequest, id: number) => {
            return fetch(`${ApiUri}/Reports/${id}`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateFavouriteReport = useCallback(
        (request: boolean, reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/favourite`, {
                method: 'PATCH',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateOrderReport = useCallback(
        (request: IUpdateReportOrderRequest, reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/order`, {
                method: 'PATCH',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateReportColumns = useCallback(
        (request: IUpdateReportColumnsRequest, reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/columns`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateReportChannels = useCallback(
        (request: IUpdateReportChannelsRequest, reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/channels`, {
                method: 'PATCH',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const updateDateFilters = useCallback(
        (request: IUpdateDateFiltersRequest, reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/date-filters`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const addReport = useCallback(
        (request: ICustomiseReportRequest) => {
            return fetch(`${ApiUri}/Reports/add`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const duplicateReport = useCallback(
        (reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/duplicate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const exportReportToCSV = useCallback(
        (reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}/export-to-csv`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const getGroupedChannels = useCallback(() => {
        return fetch(`${ApiUri}/Reports/channels`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const getEnums = useCallback(() => {
        return fetch(`${ApiUri}/Enums`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    const searchUsers = useCallback(
        (searchValue: string) => {
            return fetch(`${ApiUri}/User/search?searchValue=${searchValue}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const shareReport = useCallback(
        (reportId: number, request: IShareReportRequest) => {
            return fetch(`${ApiUri}/Reports/${reportId}/share`, {
                method: 'POST',
                body: JSON.stringify(request),
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const deleteReport = useCallback(
        (reportId: number) => {
            return fetch(`${ApiUri}/Reports/${reportId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.user?.access_token}`,
                },
            });
        },
        [auth.user?.access_token]
    );

    const logOutUser = useCallback(() => {
        return fetch(`${ApiUri}/Auth/Logout`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    }, [auth.user?.access_token]);

    return {
        isAuthenticated,
        getAcceptedTC,
        getAcceptedCookies,
        getAuthorizationContext,
        updateAcceptedTCVersion,
        acceptCookies,
        getReports,
        getReportById,
        getReportItemsById,
        updateReportColumns,
        updateReportChannels,
        addReport,
        duplicateReport,
        getGroupedChannels,
        getEnums,
        logOutUser,
        updateFavouriteReport,
        updateOrderReport,
        updateDateFilters,
        updateReport,
        exportReportToCSV,
        searchUsers,
        shareReport,
        deleteReport,
    };
}
