import { Badge, Tooltip } from '@appkit4/react-components';
import { AppRoutes } from '../../../../Constants';
import useEnums from '../../../../hooks/useEnums';
import { EnumNames } from '../../../../models/enumNames';
import { ReportDataType } from '../../../../models/reportDataType.enum';
import { ReportType } from '../../../../models/reportType.enum';
import { BreadcrumbElement } from '../../../breadcrumbElement/breadcrumbElement';
import {
    DataTypeStyleDict,
    ReportTypeStyleDict,
} from '../../../reportsPage/models/dictionariesStyleClasses';
import { NetworkNames } from '../../models/networkNames.enum';
import {
    IDynamicReportDate,
    isDynamicReportDateType,
    IStaticReportDate,
} from '../../models/reportDateType';
import { IReportDetails } from '../../models/reportDetails';
import { ReportingPeriod } from '../reportingPeriod/reportingPeriod';
import { ReportActionsButton } from './reportActions/reportActionsButton';
import styles from './reportDetailsHeader.module.scss';

type ReportDetailsHeaderProps = {
    report: IReportDetails;
    setNewReportDateType: (
        reportDateType: IStaticReportDate | IDynamicReportDate,
        reportType: ReportType
    ) => void;
};

export const ReportDetailsHeader = ({
    report,
    setNewReportDateType,
}: ReportDetailsHeaderProps) => {
    const { getEnumName } = useEnums();

    const getNetworkName = (dataTypeId: ReportDataType): string => {
        if (dataTypeId === ReportDataType.Overnight) {
            return NetworkNames.UKNetworkPanel;
        }

        return '';
    };

    return (
        <div className={styles.reportsDetailsHeaderContainer}>
            <div className={styles.detailsBox}>
                <div className={styles.detailsHeaderBox}>
                    <BreadcrumbElement
                        items={[
                            { url: AppRoutes.ReportsPage, name: 'All Reports' },
                            { url: '', name: report.name },
                        ]}
                    />
                    <div className={styles.reportsActivateBox}>
                        <div className={styles.reportHeaderBox}>
                            <div className={styles.reportHeader}>
                                {report.name}
                            </div>
                            <Tooltip
                                aria-label={'Description'}
                                position="top-left"
                                content={report.description}
                                mouseEnterDelay={50}
                            >
                                <div className={styles.descriptionIcon}>
                                    <div className="Appkit4-icon icon-information-outline ap-font-16" />
                                </div>
                            </Tooltip>
                            <ReportingPeriod
                                reportDateType={report.reportDateType}
                                setNewReportDateType={setNewReportDateType}
                                reportId={report.id}
                            />
                        </div>
                        <ReportActionsButton report={report} />
                    </div>
                </div>
                <div className={styles.badgesAndLastUpdateBox}>
                    <div className={styles.badgesOnlyBox}>
                        <Badge
                            value={getEnumName(
                                EnumNames.ReportDataType,
                                report.dataTypeId
                            )}
                            className={`${styles.badge} ${DataTypeStyleDict.get(
                                report.dataTypeId
                            )}`}
                        />

                        <Badge
                            value={getEnumName(
                                EnumNames.ReportType,
                                report.reportTypeId
                            )}
                            className={`${
                                styles.badge
                            } ${ReportTypeStyleDict.get(report.reportTypeId)}`}
                        />
                        {report.dataTypeId === ReportDataType.Overnight && (
                            <>
                                <div className={styles.breakLine} />
                                {report.reportDateType.isAllDay && (
                                    <Badge
                                        type="primary-outlined"
                                        value="All day"
                                        className={styles.badge}
                                    />
                                )}
                                {isDynamicReportDateType(
                                    report.reportDateType
                                ) &&
                                    (
                                        report.reportDateType as IDynamicReportDate
                                    ).areNotificationsOn && (
                                        <Badge
                                            type="primary-outlined"
                                            value="Notified daily"
                                            className={styles.badge}
                                        />
                                    )}
                                <Badge
                                    type="primary-outlined"
                                    value={getNetworkName(report.dataTypeId)}
                                    className={styles.badge}
                                />
                            </>
                        )}
                    </div>

                    <div>
                        <div className="Appkit4-icon icon-information-outline ap-font-16" />
                        Last Updated 01/01/1111, 01:01
                    </div>
                </div>
            </div>
        </div>
    );
};
