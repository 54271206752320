import { Tooltip } from '@appkit4/react-components';
import styles from './textWrapper.module.scss';

type TextWrapperProps = {
    text: string;
    node?: JSX.Element | undefined;
    className?: string | undefined;
    style?: React.CSSProperties | undefined;
};

export default function TextWrapper(props: TextWrapperProps) {
    const customClasses = props.className ? props.className : '';
    const tooltipLabel = 'Tooltip - ' + props.text;

    return (
        <Tooltip
            aria-label={tooltipLabel}
            position="top-left"
            content={props.text}
            mouseEnterDelay={50}
        >
            <div
                className={`${styles.textWrapper} ${customClasses}`}
                style={props.style}
            >
                {!!props.node && props.node}
                {!props.node && props.text}
            </div>
        </Tooltip>
    );
}
