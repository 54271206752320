import { appInsights } from './AppInsights';
import {
    IEventTelemetry,
    IExceptionTelemetry,
    ITraceTelemetry,
} from '@microsoft/applicationinsights-web';

export default function useLogger() {
    function trackEvent(eventData: IEventTelemetry) {
        if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
            appInsights.trackEvent(eventData);
        } else {
            console.log(eventData);
        }
    }

    function trackException(eventData: IExceptionTelemetry) {
        if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
            appInsights.trackException(eventData);
        } else {
            console.error(eventData);
        }
    }

    function trackTrace(eventData: ITraceTelemetry) {
        if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
            appInsights.trackTrace(eventData);
        } else {
            console.trace(eventData);
        }
    }

    return {
        trackEvent,
        trackException,
        trackTrace,
    };
}
