import { Button, Modal } from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import useApi from '../../api';
import { useLogout } from '../../auth/useLogout';
import {
    TermsAndConditionsVersion,
    UserTCSessionItemKey,
} from '../../Constants';
import { AcceptedTCResponse } from '../../models/acceptedTCResponse';
import { PageLoadingIndicator } from '../pageLoadingIndicator/pageLoadingIndicator';
import { TcContent } from './tcContent';
import './termsAndConditions.scss';

export const TermsAndConditions = () => {
    const { getAcceptedTC, updateAcceptedTCVersion } = useApi();
    const logout = useLogout();

    const [sendAcceptTC, setSendAcceptTC] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [userTCAcceptedVersion, setUserTCAcceptedVersion] = useState<
        number | null
    >(null);

    useEffect(() => {
        let showUpdated = false;

        if (userTCAcceptedVersion === null) {
            showUpdated = true;
        }

        if (Number(userTCAcceptedVersion) < TermsAndConditionsVersion) {
            showUpdated = true;
        }

        setShowModal(showUpdated);
    }, [userTCAcceptedVersion]);

    useEffect(() => {
        const sessionUserTCAcceptedVersion =
            window.sessionStorage.getItem(UserTCSessionItemKey);

        const getVersionFromAPI = async () => {
            const apiResponse: Response = await getAcceptedTC();

            if (apiResponse.status === 403) {
                setShowLoading(false);
                return;
            }

            const apiResponseData: AcceptedTCResponse =
                await apiResponse.json();

            window.sessionStorage.setItem(
                UserTCSessionItemKey,
                JSON.stringify(apiResponseData.acceptedTCVersion)
            );
            setUserTCAcceptedVersion(apiResponseData.acceptedTCVersion);
            setShowLoading(false);
        };

        if (sessionUserTCAcceptedVersion === null) {
            setShowLoading(true);
            getVersionFromAPI();
        } else {
            setUserTCAcceptedVersion(JSON.parse(sessionUserTCAcceptedVersion));
            setShowLoading(false);
        }
    }, [getAcceptedTC]);

    useEffect(() => {
        if (!sendAcceptTC) return;

        const postAcceptTC = async () => {
            setShowModal(false);
            setShowLoading(true);
            await updateAcceptedTCVersion(TermsAndConditionsVersion);

            window.sessionStorage.setItem(
                UserTCSessionItemKey,
                TermsAndConditionsVersion.toString()
            );

            setUserTCAcceptedVersion(TermsAndConditionsVersion);
            setShowLoading(false);
            setSendAcceptTC(false);
        };
        postAcceptTC();
    }, [sendAcceptTC, updateAcceptedTCVersion]);

    if (showLoading) {
        return <PageLoadingIndicator showLoading={showLoading} />;
    }

    return (
        <>
            {showModal && (
                <Modal
                    title={''}
                    visible={true}
                    modalStyle={{ width: '960px' }}
                    footerStyle={{
                        paddingTop: 'var(--spacing-3)',
                        marginTop: '5px',
                        minHeight: '64px',
                    }}
                    header={
                        <span style={{ fontSize: 24 }}>
                            Terms and conditions
                        </span>
                    }
                    icons={''}
                    footer={
                        <>
                            <Button
                                aria-label="Log out"
                                onClick={logout}
                                kind="secondary"
                            >
                                Log out
                            </Button>
                            <Button
                                aria-label="Accept"
                                onClick={() => setSendAcceptTC(true)}
                            >
                                Accept
                            </Button>
                        </>
                    }
                    bodyStyle={{ minHeight: '92px' }}
                    maskCloseable={false}
                    blockScroll={false}
                >
                    <TcContent />
                </Modal>
            )}
        </>
    );
};
