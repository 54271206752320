import HeaderOptionItem from '@appkit4/react-components/esm/header/HeaderOptionItem';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../Constants';

export default function NavigationOptionTemplate() {
    const navigate = useNavigate();

    const activeClass = {
        backgroundColor:
            ' rgba(var(--color-background-hover-selected-rgb), var(--opacity-6))',
        backdropFilter: 'blur(1.25rem)',
    };

    const isActive = (path: string) => {
        const pathname = window.location.pathname;
        return pathname === path ? activeClass : {};
    };

    return (
        <>
            <HeaderOptionItem
                aria-label="Dashboard"
                iconName="home-outline"
                label="Dashboard"
                style={isActive(AppRoutes.HomePage)}
                onClick={() => navigate(AppRoutes.HomePage)}
            />
            <HeaderOptionItem
                aria-label="Reports"
                iconName="document-text-outline"
                label="Reports"
                style={isActive(AppRoutes.ReportsPage)}
                onClick={() => navigate(AppRoutes.ReportsPage)}
            />
        </>
    );
}
