import {
    Avatar,
    DropdownButton,
    ItemDataType,
    ValueType,
} from '@appkit4/react-components';
import { Button } from '@appkit4/react-components/button';
import { useAuth } from 'react-oidc-context';
import { useLogout } from '../../../auth/useLogout';

export default function NavigationUserTemplate() {
    const auth = useAuth();
    const logout = useLogout();

    const dropwDownData = [
        {
            value: 'logout',
            label: 'Logout',
            iconName: 'lockclosed-locked-outline',
        },
    ];

    const handleOnSelect = (value: ValueType) => {
        if (value === 'logout') {
            logout();
        }
    };

    const getUserInitials = () => {
        if (
            auth.user &&
            auth.user.profile.given_name &&
            auth.user.profile.family_name
        ) {
            return (
                auth.user.profile.given_name?.substring(0, 1) +
                auth.user.profile.family_name?.substring(0, 1)
            );
        }

        return '??';
    };

    if (!auth.isAuthenticated) {
        return <Button onClick={() => auth.signinRedirect()}>Log in</Button>;
    }

    return (
        <>
            <DropdownButton
                customTriggerNode={true}
                dropdownStyle={{ left: 'auto' }}
                onSelect={handleOnSelect}
                data={dropwDownData}
                prefixTemplate={(item: ItemDataType) => (
                    <span className={`Appkit4-icon icon-${item.iconName}`} />
                )}
            >
                <Avatar
                    label={`${getUserInitials()}`}
                    disabled={false}
                    ariaLabel="settings menu"
                />
            </DropdownButton>
        </>
    );
}
