import {
    Button,
    Input,
    ItemDataType,
    Panel,
    Select,
    TextArea,
} from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import useApi from '../../api';
import { ICustomiseReportRequest } from '../../api/models/customiseReportRequest';
import { useConfirmExitModal } from '../../hooks/useConfirmExitModal';
import useEnums from '../../hooks/useEnums';
import { useNavigateBack } from '../../hooks/useNavigateBack';
import { EnumNames } from '../../models/enumNames';
import { ReportDataType } from '../../models/reportDataType.enum';
import { BreadcrumbElement } from '../breadcrumbElement/breadcrumbElement';
import { IReportDetails } from '../reportDetailsPage/models/reportDetails';
import styles from './customiseReportPage.module.scss';

export const CustomiseReportPage = () => {
    const { enumDictionary } = useEnums();
    const { confirmExitModal } = useConfirmExitModal(AppRoutes.ReportsPage);
    const { navigateBack } = useNavigateBack(AppRoutes.ReportsPage);
    const navigate = useNavigate();
    const { addReport, updateReport } = useApi();
    const location = useLocation();

    const [name, setName] = useState<string>(location.state?.name ?? '');
    const [description, setDescription] = useState<string>(
        location.state?.description ?? ''
    );
    const [dataTypeId, setDataTypeId] = useState<ReportDataType | undefined>(
        location.state?.dataTypeId ?? undefined
    );
    const [id, setId] = useState<number | undefined>(
        location.state?.id ?? undefined
    );
    const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);

    const dataTypeData: ItemDataType[] = enumDictionary
        ? enumDictionary[EnumNames.ReportDataType].map((report) => ({
              value: report.id,
              label: report.name,
          }))
        : [];

    const handleTouchForm = () => {
        if (!shouldBlockNavigation) {
            setShouldBlockNavigation(true);
        }
    };

    const handleCancelButton = () => {
        if (shouldBlockNavigation) {
            confirmExitModal();
        } else {
            navigateBack();
        }
    };

    const handleSelectDataType = (value: SelectValue) => {
        handleTouchForm();
        setDataTypeId(value as ReportDataType);
    };

    const handleSaveButton = async (event: any) => {
        event.preventDefault();
        if (!dataTypeId || !name || !description) {
            setShouldShowErrorMessage(true);
            return;
        }
        setSaveButtonLoading(true);

        const request: ICustomiseReportRequest = {
            name: name,
            description: description,
            dataTypeId: dataTypeId,
        };

        try {
            if (id == null) {
                const newReportResponse = await addReport(request);
                const newReport: IReportDetails =
                    await newReportResponse.json();
                navigate(`${AppRoutes.ReportDetails}/${newReport.id}`);
            } else {
                const updateReportResponse = await updateReport(request, id);
                const updatedReport: IReportDetails =
                    await updateReportResponse.json();
                navigate(`${AppRoutes.ReportDetails}/${updatedReport.id}`);
            }
        } finally {
            setSaveButtonLoading(false);
        }
    };

    const breadcrumbItems = [
        { url: AppRoutes.ReportsPage, name: 'All reports' },
        { url: AppRoutes.CustomiseReport, name: 'Customise report' },
    ];

    return (
        <form className={styles.formContainer}>
            <div className={styles.headerContainer}>
                <BreadcrumbElement items={breadcrumbItems} />
                <div className={styles.header}>
                    <span className={styles.reportsCustomiseText}>
                        Customise report
                    </span>
                    <span className={styles.buttonsBox}>
                        <Button
                            kind="secondary"
                            aria-label="Cancel"
                            onClick={handleCancelButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            aria-label="Save"
                            onClick={handleSaveButton}
                            loading={saveButtonLoading}
                        >
                            Save
                        </Button>
                    </span>
                </div>
            </div>
            <Panel className={styles.panel}>
                <div className={styles.inputsContainer}>
                    <p>* indicates a required field</p>
                    <Input
                        required
                        name="reportName"
                        type="text"
                        title="Report Name"
                        inputProps={{
                            required: true,
                            maxLength: 50,
                        }}
                        value={name}
                        onChange={setName}
                        onBlur={handleTouchForm}
                    />
                    <TextArea
                        required
                        name="reportDescription"
                        title="Enter report description"
                        maxLength={200}
                        value={description}
                        onChange={setDescription}
                        onBlur={handleTouchForm}
                    />
                    <Select
                        id="dataType"
                        required={true}
                        data={dataTypeData}
                        placeholder="Data type"
                        value={dataTypeId}
                        onSelect={handleSelectDataType}
                    />
                    {shouldShowErrorMessage && (
                        <p className={styles.errorMessage}>
                            Please enter a valid input for all required fields
                        </p>
                    )}
                </div>
            </Panel>
        </form>
    );
};
