import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import { useSessionStorage } from '../../hooks/useSessionStorage';

type ProtectedCallbackProps = { children?: JSX.Element };

export const ProtectedCallback: FC<ProtectedCallbackProps> = () => {
    const auth = useAuth();

    const { value: returnRoute } = useSessionStorage('returnRoute', null);

    if (auth.isLoading) {
        return <div aria-label="loading">Loading...</div>;
    }

    if (auth.error) {
        return (
            <div aria-label="authentication-error">
                Authentication error: {auth.error.message}
            </div>
        );
    }

    if (!auth.isAuthenticated) {
        return <Navigate to={AppRoutes.LandingPage} replace />;
    }

    if (!returnRoute) {
        return <Navigate to={AppRoutes.HomePage} replace />;
    }

    return <Navigate to={returnRoute} replace />;
};
