import { Pagination, PaginationRef, Select } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { forwardRef } from 'react';
import styles from './paginationWithPageSize.module.scss';

type PaginationWithPageSizeProps = {
    setCurrentPage: (currentPage: number) => void;
    currentPage: number;
    totalPageCount: number;
    setPageSize: (newPageSize: number) => void;
    pageSize: number;
    availablePageSizes: number[];
};

type PageSize = {
    value: number;
    label: string;
};

export const PaginationWithPageSize = forwardRef(
    (props: PaginationWithPageSizeProps, ref?: React.Ref<PaginationRef>) => {
        const dropdownData = props.availablePageSizes.map((size) => {
            const pageSize: PageSize = { value: size, label: size.toString() };
            return pageSize;
        });

        const handleSelectPageSize = (value: SelectValue) => {
            const newPageSize: number = value as number;
            const newPageNumber =
                Math.floor(
                    (props.pageSize * (props.currentPage - 1) + 1) / newPageSize
                ) + 1;
            props.setCurrentPage(newPageNumber);
            props.setPageSize(newPageSize);
        };

        const handlePageChange = (pageNumber: number) => {
            if (!pageNumber || pageNumber < 1) return;
            props.setCurrentPage(pageNumber);
        };

        return (
            <div className={styles.paginationContainer}>
                <div className={styles.paginationSelectWrapper}>
                    <div className={styles.paginationSelectItemLeft}>Show</div>
                    <Select
                        aria-label="Select"
                        className={styles.paginationSelect}
                        dropdownAlwaysDown={false}
                        hideTitleOnInput={true}
                        value={props.pageSize}
                        data={dropdownData}
                        onSelect={handleSelectPageSize}
                        searchable={false}
                    />
                    <div className={styles.paginationSelectItemLeft}>
                        Items / Page
                    </div>
                </div>
                <Pagination
                    ref={ref}
                    aria-label="Pagination"
                    total={props.totalPageCount}
                    onPageChange={handlePageChange}
                />
            </div>
        );
    }
);
PaginationWithPageSize.displayName = 'PaginationWithPageSize';
