import { Button, Modal, Select } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useState } from 'react';
import useApi from '../../../../api';
import { IUpdateDateFiltersRequest } from '../../../../api/models/updateDateFiltersRequest';
import useLogger from '../../../../api/monitoring/useLogger';
import { ReportType } from '../../../../models/reportType.enum';
import {
    IDynamicReportDate,
    IStaticReportDate,
    isDynamicReportDateType,
    isStaticReportDateType,
} from '../../models/reportDateType';
import { DynamicFilter } from './dynamicFilters';
import filterValidator from './filterValidator';
import styles from './reportingPeriod.module.scss';
import { StaticFilter } from './staticFilters';
import { TimeRangeValues } from './timeRange';

type ReportingPeriodProps = {
    reportDateType: IStaticReportDate | IDynamicReportDate;
    setNewReportDateType: (
        reportDateType: IStaticReportDate | IDynamicReportDate,
        reportType: ReportType
    ) => void;
    reportId: number;
};

export const ReportingPeriod = (props: ReportingPeriodProps) => {
    const title = 'Edit reporting period';
    const { updateDateFilters } = useApi();
    const logger = useLogger();
    const [modalVisible, setModalVisible] = useState(false);
    const [shouldDisplayError, setShouldDisplayError] = useState(false);
    const { validateDynamic, validateStatic } = filterValidator();

    const getReportType = () => {
        if (isDynamicReportDateType(props.reportDateType)) {
            return ReportType.Dynamic;
        } else if (isStaticReportDateType(props.reportDateType)) {
            return ReportType.Static;
        }
        return ReportType.Dynamic;
    };
    const getDynamicFilter = (): IDynamicReportDate => {
        if (isDynamicReportDateType(props.reportDateType)) {
            return props.reportDateType as IDynamicReportDate;
        }
        return {} as IDynamicReportDate;
    };
    const getStaticFilter = (): IStaticReportDate => {
        if (isStaticReportDateType(props.reportDateType)) {
            return props.reportDateType as IStaticReportDate;
        }
        return {} as IStaticReportDate;
    };
    const [reportType, setReportType] = useState<ReportType>(getReportType);
    const [dynamicFilter, setDynamicFilter] =
        useState<IDynamicReportDate>(getDynamicFilter);
    const [staticFilter, setStaticFilter] =
        useState<IStaticReportDate>(getStaticFilter);

    const handleOpenModal = () => setModalVisible(true);
    const onKeyDown = (event: any) => {
        event.preventDefault();
        if (event.key === 'Enter' || event.key === ' ') {
            handleOpenModal();
        }
    };

    const handleClickSave = async () => {
        if (reportType === ReportType.Dynamic) {
            return await handleSaveDynamic();
        }
        if (reportType === ReportType.Static) {
            return await handleSaveStatic();
        }
    };

    const handleSaveDynamic = async () => {
        if (!dynamicFilter || !validateDynamic(dynamicFilter)) {
            setShouldDisplayError(true);
            return;
        }

        if (dynamicFilter.isAllDay) {
            dynamicFilter.fromHour = TimeRangeValues.Minimum;
            dynamicFilter.toHour = TimeRangeValues.Maximum;
        }

        setStaticFilter({} as IStaticReportDate);
        setReportType(ReportType.Dynamic);
        await handleSave(dynamicFilter, undefined);
    };

    const handleSaveStatic = async () => {
        if (!staticFilter || !validateStatic(staticFilter)) {
            setShouldDisplayError(true);
            return;
        }

        if (staticFilter.isAllDay) {
            staticFilter.fromHour = TimeRangeValues.Minimum;
            staticFilter.toHour = TimeRangeValues.Maximum;
        }

        setDynamicFilter({} as IDynamicReportDate);
        setReportType(ReportType.Static);
        await handleSave(undefined, staticFilter);
    };

    const handleSave = async (
        dynamicFilter?: IDynamicReportDate,
        staticFilter?: IStaticReportDate
    ) => {
        try {
            const request: IUpdateDateFiltersRequest = {
                dynamicType: dynamicFilter,
                staticType: staticFilter,
            };
            const response = await updateDateFilters(request, props.reportId);
            if (response.ok) {
                setShouldDisplayError(false);
                if (dynamicFilter) {
                    props.setNewReportDateType(dynamicFilter, reportType);
                } else if (staticFilter) {
                    props.setNewReportDateType(staticFilter, reportType);
                }
            }
        } catch (error: any) {
            logger.trackException(error);
        } finally {
            setModalVisible(false);
        }
    };

    const handleSelectFilterType = (selectedType: SelectValue) => {
        setReportType(selectedType as ReportType);
    };

    const handleSetStaticFilters = (value: IStaticReportDate) => {
        setStaticFilter(value);
    };

    const handleSetDynamicFilters = (value: IDynamicReportDate) => {
        setDynamicFilter(value);
    };

    const filterTypes = [
        { value: ReportType.Static, label: 'Fixed date (static data)' },
        { value: ReportType.Dynamic, label: 'Relative date (dynamic data)' },
    ];

    return (
        <>
            <div
                aria-label="Edit reporting period"
                role="button"
                tabIndex={0}
                className={styles.reportHeaderInfo}
                onClick={handleOpenModal}
                onKeyDown={onKeyDown}
            >
                {title}
            </div>
            <Modal
                onCancel={() => setModalVisible(false)}
                visible={modalVisible}
                title={title}
                ariaLabel={title}
                maskCloseable={false}
                bodyStyle={{ minHeight: '350px', minWidth: '500px' }}
                footer={
                    <>
                        <Button
                            onClick={() => setModalVisible(false)}
                            kind="secondary"
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleClickSave}>Save changes</Button>
                    </>
                }
            >
                <Select
                    className={styles.modalReportTypeSelect}
                    placeholder="Reporting period"
                    data={filterTypes}
                    value={reportType}
                    onSelect={handleSelectFilterType}
                />
                {reportType === ReportType.Dynamic && (
                    <DynamicFilter
                        filters={dynamicFilter}
                        setFilters={handleSetDynamicFilters}
                        shouldDisplayError={shouldDisplayError}
                    />
                )}
                {reportType === ReportType.Static && (
                    <StaticFilter
                        filters={staticFilter}
                        setFilters={handleSetStaticFilters}
                        shouldDisplayError={shouldDisplayError}
                    />
                )}
            </Modal>
        </>
    );
};
