import { Loading, Modal } from '@appkit4/react-components';
import styles from './pageLoadingIndicator.module.scss';

type PageLoadingIndicatorProps = {
    showLoading: boolean;
    text?: string;
};

export const PageLoadingIndicator = (props: PageLoadingIndicatorProps) => {
    return (
        <>
            {props.showLoading && (
                <Modal
                    visible={true}
                    header={
                        <span style={{ fontSize: '1.5rem' }}>
                            {props.text ? props.text : 'Loading...'}
                        </span>
                    }
                    className={styles.loadingModal}
                >
                    <Loading
                        role="modal"
                        aria-modal="true"
                        aria-label="loading"
                        loadingType="circular"
                        indeterminate={true}
                    />
                </Modal>
            )}
        </>
    );
};
