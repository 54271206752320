import './styles/styles.scss';

import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes } from './Constants';
import { AppInsightsContextProvider } from './api/monitoring/AppInsightsContextProvider';
import { AuthenticationWrapper } from './auth/authenticationWrapper';
import { oidcConfig } from './auth/config';
import { CustomiseReportPage } from './components/customiseReportPage/customiseReportPage';
import { ErrorBoundary } from './components/errorBoundary/errorBoundary';
import { HomePage } from './components/homePage';
import { LandingPage } from './components/landingPage/landingPage';
import { Layout } from './components/layout/layout';
import { NotFoundPage } from './components/notFoundPage';
import { ProtectedCallback } from './components/protectedRoutes/protectedCallback';
import { ProtectedRedirect } from './components/protectedRoutes/protectedRedirect';
import { ReportsDetailsPage } from './components/reportDetailsPage/reportDetailsPage';
import { ReportsPage } from './components/reportsPage/reportsPage';
import { StaticDataWrapper } from './components/staticDataWrapper/staticDataWrapper';
import { UnauthorizedPage } from './components/unauthorizedPage';

function App() {
    return (
        <AppInsightsContextProvider>
            <ErrorBoundary>
                <AuthProvider {...oidcConfig}>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                index
                                element={<Navigate to={AppRoutes.HomePage} />}
                            />
                            <Route
                                path={AppRoutes.LandingPage}
                                element={<LandingPage />}
                            />
                            <Route
                                path={AppRoutes.UnauthorizedPage}
                                element={<UnauthorizedPage />}
                            />
                            <Route
                                path={AppRoutes.NotFound}
                                element={<NotFoundPage />}
                            />
                            <Route
                                element={
                                    <AuthenticationWrapper>
                                        <ProtectedRedirect>
                                            <StaticDataWrapper>
                                                <Layout />
                                            </StaticDataWrapper>
                                        </ProtectedRedirect>
                                    </AuthenticationWrapper>
                                }
                            >
                                <Route
                                    path={AppRoutes.HomePage}
                                    element={<HomePage />}
                                />
                                <Route
                                    path={AppRoutes.ReportsPage}
                                    element={<ReportsPage />}
                                />
                                <Route
                                    path={AppRoutes.ReportDetails + '/:id'}
                                    element={<ReportsDetailsPage />}
                                />
                                <Route
                                    path={AppRoutes.CustomiseReport}
                                    element={<CustomiseReportPage />}
                                />
                            </Route>
                            <Route
                                path="/login/callback"
                                element={<ProtectedCallback />}
                            />
                            <Route
                                path="*"
                                element={<Navigate to={AppRoutes.NotFound} />}
                            />
                        </Routes>
                    </BrowserRouter>
                </AuthProvider>
            </ErrorBoundary>
        </AppInsightsContextProvider>
    );
}

export default App;
