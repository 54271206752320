import { Modal } from '@appkit4/react-components/modal';
import { AppRoutes } from '../Constants';
import { useNavigateBack } from './useNavigateBack';

export const useConfirmExitModal = (exitRoute: AppRoutes) => {
    const { navigateBack } = useNavigateBack(exitRoute);

    const confirmExitModal = () => {
        const modalFunction = Modal.confirm({
            title: 'Are you sure you want to exit without saving?',
            ariaLabel: 'The exit without saving confirmation modal',
            cancelshow: true,
            maskCloseable: true,
            closeOnPressEscape: true,
            footerStyle: {
                paddingTop: '8px',
                marginTop: '-8px',
                minHeight: '64px',
            },
            okText: 'Exit without saving',
            cancelText: 'Stay on Page',
            content: (
                <p>
                    The changes you&apos;ve made to this form won&apos;t be
                    saved.
                </p>
            ),
            bodyStyle: { minHeight: '92px' },
            onOk: navigateBack,
        });

        return modalFunction;
    };

    return { confirmExitModal };
};
