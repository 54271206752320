import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CookiesFooterCover } from '../cookiesFooterCover/cookiesFooterCover';
import { TermsAndConditions } from '../termsAndConditions/termsAndConditions';
import { Content } from './content/content';
import Navigation from './navigation/navigation';

type LayoutProps = { children?: JSX.Element };

export const Layout: FC<LayoutProps> = (props: LayoutProps) => {
    return (
        <>
            <TermsAndConditions />
            <Navigation />
            <Content>{props.children ? props.children : <Outlet />}</Content>
            <CookiesFooterCover />
        </>
    );
};
