import {
    Button,
    Loading,
    Notification,
    toaster,
    Tooltip,
} from '@appkit4/react-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../../../api';
import { AppRoutes, ToastGlobalConfig } from '../../../../../Constants';
import DuplicateModal from '../../../../duplicateModal/duplicateModal';
import { IReport } from '../../../models/report';
import styles from './reportPanelFooter.module.scss';

const formatDate = (date: Date) =>
    date.toLocaleString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    });

type ReportPanelFooterProps = {
    report: IReport;
    onFavouriteChanged(isFavourite: boolean): void;
};

export const ReportPanelFooter = (props: ReportPanelFooterProps) => {
    const navigate = useNavigate();
    const { updateFavouriteReport, duplicateReport } = useApi();
    const [isFavourite, setIsFavourite] = useState<boolean>(
        props.report.isFavourite
    );
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const mouseEnterDelayMs = 50;

    const handleChangeFavouriteReport = async (
        isCurrentFavourite: boolean,
        reportId: number
    ) => {
        setShowLoading(true);
        try {
            const response = await updateFavouriteReport(
                !isCurrentFavourite,
                reportId
            );
            const isReportFavourite = await response.json();
            setIsFavourite(isReportFavourite);
            props.onFavouriteChanged(isReportFavourite);
            if (isReportFavourite) {
                toaster.notify(notificationFavourite, {
                    position: ToastGlobalConfig.PositionGlobal,
                    showTimer: ToastGlobalConfig.ShowTimer,
                    duration: ToastGlobalConfig.Duration,
                });
            }
        } finally {
            setShowLoading(false);
        }
    };

    const handleOpenDetails = (reportId: number) => {
        navigate(`${AppRoutes.ReportDetails}/${reportId}`);
    };

    const handleDuplicate = async () => {
        try {
            const response = await duplicateReport(props.report.id);
            const newReportId = await response.json();
            handleOpenDetails(newReportId);
        } finally {
            setShowDuplicateModal(false);
        }
    };

    const notificationFavourite = (
        <Notification
            message={<div>Added to favourites</div>}
            status="success"
        ></Notification>
    );

    return (
        <div className={styles.panelFooter}>
            <span>{formatDate(props.report.lastUpdatedDate)}</span>
            <span className={styles.actionsCell}>
                <Tooltip
                    position="top-left"
                    content="Favourite"
                    mouseEnterDelay={mouseEnterDelayMs}
                >
                    {!showLoading && (
                        <Button
                            aria-label="Favourite"
                            data-theme="black"
                            kind="text"
                            compact
                            className={`Appkit4-icon icon-${
                                isFavourite ? 'rating-fill' : 'star-outline'
                            } ${styles.actionButton}`}
                            onClick={() =>
                                handleChangeFavouriteReport(
                                    isFavourite,
                                    props.report.id
                                )
                            }
                        />
                    )}
                    {showLoading && (
                        <Loading
                            aria-label="loading"
                            loadingType="circular"
                            compact="true"
                            indeterminate={true}
                            className={styles.loading}
                        />
                    )}
                </Tooltip>

                <Tooltip
                    position="top-left"
                    content="Duplicate"
                    mouseEnterDelay={mouseEnterDelayMs}
                >
                    <Button
                        aria-label="Duplicate"
                        data-theme="black"
                        kind="text"
                        compact
                        className={`Appkit4-icon icon-duplicate-outline ${styles.actionButton}`}
                        onClick={() => setShowDuplicateModal(true)}
                    />
                </Tooltip>
                <Tooltip
                    position="top-left"
                    content="View"
                    mouseEnterDelay={mouseEnterDelayMs}
                >
                    <Button
                        aria-label="View"
                        data-theme="black"
                        kind="text"
                        compact
                        className={`Appkit4-icon icon-arrow-right-outline ${styles.actionButton}`}
                        onClick={() => handleOpenDetails(props.report.id)}
                    />
                </Tooltip>
            </span>
            {showDuplicateModal && (
                <DuplicateModal
                    reportName={props.report.name}
                    onCancel={() => setShowDuplicateModal(false)}
                    onDuplicate={handleDuplicate}
                />
            )}
        </div>
    );
};
