import { FC, useEffect, useState } from 'react';
import useApi from '../../api';
import { StaticDataContext } from '../../contexts/StaticDataContext';
import { Enum } from '../../models/enum';
import { GroupedChannels } from '../../models/groupedChannels';
import { StaticData } from '../../models/staticData';
import { PageLoadingIndicator } from '../pageLoadingIndicator/pageLoadingIndicator';

type StaticDataWrapperProps = { children: JSX.Element };

export const StaticDataWrapper: FC<StaticDataWrapperProps> = ({ children }) => {
    const { getEnums, getGroupedChannels } = useApi();
    const [state, setState] = useState<StaticData | undefined>(undefined);

    useEffect(() => {
        const fetchEnums = async () => {
            const enumsResponse = await getEnums();
            const enumsData: Record<string, Enum[]> =
                await enumsResponse.json();

            const groupedChannelsResponse = await getGroupedChannels();
            const groupedChannelsData: GroupedChannels =
                await groupedChannelsResponse.json();

            const state: StaticData = {
                enumDictionary: enumsData,
                groupedChannels: groupedChannelsData,
            };

            setState(state);
        };

        if (!state) {
            fetchEnums();
        }
    }, [state, getEnums, getGroupedChannels]);

    return (
        <>
            {state && (
                <StaticDataContext.Provider value={state}>
                    {children}
                </StaticDataContext.Provider>
            )}
            <PageLoadingIndicator showLoading={!state} />
        </>
    );
};
