import { FC } from 'react';

type HighlightPartiallyTextProps = {
    text: string;
    highlightedValue: string;
};

export const HighlightPartiallyText: FC<HighlightPartiallyTextProps> = ({
    text,
    highlightedValue,
}: HighlightPartiallyTextProps) => {
    const regex = new RegExp(highlightedValue, 'i');
    const searchTermIndex = regex.exec(text)?.index ?? -1;

    const isValueMissing = !highlightedValue.length || searchTermIndex === -1;

    const renderHighlightPartiallyText = () => {
        const front = text.substring(0, searchTermIndex);
        const middle = text.substring(
            searchTermIndex,
            searchTermIndex + highlightedValue.length
        );
        const back = text.substring(searchTermIndex + highlightedValue.length);

        return (
            <>
                {front}
                {middle && (
                    <span className={'ap-bg-states-warning-05'}>{middle}</span>
                )}
                {middle && back}
            </>
        );
    };

    return (
        <>
            {isValueMissing && <>{text}</>}
            {!isValueMissing && renderHighlightPartiallyText()}
        </>
    );
};
