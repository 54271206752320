import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../Constants';

export const useNavigateBack = (backRoute: AppRoutes) => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateBack = () => {
        if (location.key !== 'default') {
            navigate(-1);
        } else {
            navigate(backRoute);
        }
    };

    return { navigateBack };
};
