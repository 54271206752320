import styles from './filterEmptyBox.module.scss';
export const FilterEmptyBox = () => {
    return (
        <div className={styles.filterEmptyBoxContainer}>
            <span className={`Appkit4-icon icon-filter-outline`}></span>
            <div className={styles.emptyFilterBoxText}>
                No results for the filters applied.
            </div>
        </div>
    );
};
