import { Footer } from '@appkit4/react-components';

export const AppFooter = () => {
    const footerContent =
        '© 2023 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.';
    const footerType = 'links';
    const footerLinks = [
        { name: 'Privacy policy', href: '#' },
        { name: 'Terms and conditions', href: '#' },
        { name: 'Cookie notice', href: '#' },
    ];

    return (
        <Footer
            content={footerContent}
            type={footerType}
            links={footerLinks}
            style={{ marginTop: 'auto' }}
        ></Footer>
    );
};
