import { Header } from '@appkit4/react-components';
import { titleTemplate } from '../layout/titleTemplate/titleTemplate';

export const ErrorPage = () => {
    return (
        <>
            <Header
                type="transparent"
                titleTemplate={titleTemplate}
                style={{ minWidth: '0' }}
            />
            <div style={{ margin: '20px' }}>
                <h1 aria-label="error-message">
                    Sorry, there was an error. Please try again later.
                </h1>
            </div>
        </>
    );
};
