import { Header } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, MinimumPixelSizeForTitle } from '../../../Constants';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { titleTemplate } from '../titleTemplate/titleTemplate';
import NavigationOptionTemplate from './navigationOptionTemplate';
import NavigationUserTemplate from './navigationUserTemplate';

const Navigation = () => {
    const navigate = useNavigate();
    const { width } = useWindowSize();

    const doesTitleFit = width >= MinimumPixelSizeForTitle;

    return (
        <>
            <Header
                type="transparent"
                style={{
                    minWidth: '0',
                }}
                onClickLogo={() => navigate(AppRoutes.HomePage)}
                titleTemplate={doesTitleFit ? titleTemplate : undefined}
                optionsTemplate={NavigationOptionTemplate}
                userTemplate={NavigationUserTemplate}
            />
        </>
    );
};

export default Navigation;
