import { Button, Modal } from '@appkit4/react-components';

type DeleteModalProps = {
    reportName: string;
    onCancel: () => void;
    onDelete: () => void;
};
export const DeleteModal = (props: DeleteModalProps) => {
    const modalTitle = 'Delete this report';

    const handleDelete = () => {
        props.onDelete();
    };

    return (
        <Modal
            visible={true}
            ariaLabel={'The modal for delete report'}
            title={modalTitle}
            bodyStyle={{ minHeight: '50px' }}
            footer={
                <>
                    <Button
                        aria-label="Cancel"
                        onClick={props.onCancel}
                        kind="secondary"
                    >
                        Cancel
                    </Button>
                    <Button aria-label="Delete" onClick={handleDelete}>
                        Delete
                    </Button>
                </>
            }
            footerStyle={{
                paddingTop: '8px',
                marginTop: '-8px',
            }}
            maskCloseable={true}
            closeOnPressEscape={true}
            onCancel={props.onCancel}
        >
            <p>
                Are you sure you want to delete{' '}
                <strong>{props.reportName}</strong> ?
            </p>
        </Modal>
    );
};
