import { Badge } from '@appkit4/react-components';
import useEnums from '../../../../../hooks/useEnums';
import { EnumNames } from '../../../../../models/enumNames';
import {
    DataTypeStyleDict,
    ReportTypeStyleDict,
} from '../../../models/dictionariesStyleClasses';
import { IReport } from '../../../models/report';
import TextWrapper from '../../textWrapper/textWrapper';
import styles from './reportPanelHeader.module.scss';

type ReportPanelHeaderProps = {
    report: IReport;
    node?: JSX.Element | undefined;
};

const ReportPanelHeader = (props: ReportPanelHeaderProps) => {
    const { getEnumName } = useEnums();

    return (
        <div className={styles.panelHeader}>
            <TextWrapper
                className={styles.reportName}
                text={props.report.name}
                node={props.node}
            />
            <div className={styles.reportBadges}>
                <Badge
                    value={getEnumName(
                        EnumNames.ReportDataType,
                        props.report.dataTypeId
                    )}
                    className={DataTypeStyleDict.get(props.report.dataTypeId)}
                />

                <Badge
                    value={getEnumName(
                        EnumNames.ReportType,
                        props.report.reportTypeId
                    )}
                    className={ReportTypeStyleDict.get(
                        props.report.reportTypeId
                    )}
                />
            </div>
        </div>
    );
};

export default ReportPanelHeader;
