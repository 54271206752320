import { PaginationRef } from '@appkit4/react-components';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../api';
import { IGetReportItemsRequest } from '../../api/models/getReportItemsRequest';
import { IPagedResponse } from '../../api/models/pagedResponse';
import { AppRoutes } from '../../Constants';
import { ReportType } from '../../models/reportType.enum';
import { EntityEmptyBox } from '../entityEmptyBox/entityEmptyBox';
import { FilterEmptyBox } from '../filterEmptyBox/filterEmptyBox';
import { PageLoadingIndicator } from '../pageLoadingIndicator/pageLoadingIndicator';
import { PaginationWithPageSize } from '../paginationWithPageSize/paginationWithPageSize';
import { ReportDetailsHeader } from './components/reportDetailsHeader/reportDetailsHeader';
import { ReportTable } from './components/reportTable/reportTable';
import { ReportTableActions } from './components/reportTableActions/reportTableActions';
import { IOvernightDataItem } from './models/overnightDataItem';
import { IReportColumn } from './models/reportColumn';
import { IDynamicReportDate, IStaticReportDate } from './models/reportDateType';
import { IReportDetails } from './models/reportDetails';
import { ReportItemsFilter } from './models/reportItemsFilter';
import { ReportItemsOrder } from './models/reportItemsOrder';
import styles from './reportDetailsPage.module.scss';

type ReportsDetailsPageProps = {
    id: string;
};

const formatDate = (date: Date) =>
    date.toLocaleString('en-GB', {
        hour: 'numeric',
        minute: 'numeric',
    });

export const ReportsDetailsPage = () => {
    const { getReportItemsById, getReportById } = useApi();
    const navigate = useNavigate();
    const { id: reportId } = useParams<ReportsDetailsPageProps>();

    const paginationRef = useRef() as MutableRefObject<PaginationRef>;

    const [showLoading, setShowLoading] = useState(false);
    const [parsedReportId, setParsedReportId] = useState<number | undefined>();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [isReportDetailsLoaded, setIsReportDetailsLoaded] = useState(false);
    const [reportDetails, setReportDetails] = useState<
        IReportDetails | undefined
    >();
    const [filters, setFilters] = useState<ReportItemsFilter>({
        searchValue: '',
        channels: [],
    });
    const [ordersChanged, setOrdersChanged] = useState<boolean>(false);
    const [dateTypeChanged, setDateTypeChanged] = useState<boolean>(false);
    const [paginatedReportsItems, setPaginatedReportsItems] = useState<
        IOvernightDataItem[]
    >([]);

    const emptyFilters =
        filters.searchValue === '' && filters.channels.length === 0;

    const emptyReportItems = paginatedReportsItems.length === 0;
    const allColumnHidden =
        reportDetails?.columns.every((c) => !c.isVisible) || false;

    const handleSetNewColumns = (newColumns: IReportColumn[]) => {
        if (!reportDetails) return;

        const updatedReportDetails: IReportDetails = {
            ...reportDetails,
            columns: newColumns,
        };

        setReportDetails(updatedReportDetails);
    };

    const handleSortOrderChange = (order: ReportItemsOrder) => {
        if (!reportDetails) {
            return;
        }

        const updatedReportDetails: IReportDetails = {
            ...reportDetails,
            sortKey: order.sortKey,
            isOrderDescending: order.isOrderDescending,
        };

        setReportDetails(updatedReportDetails);
        setOrdersChanged(!ordersChanged);
    };

    const handleSetReportDateType = (
        reportDateType: IStaticReportDate | IDynamicReportDate,
        reportType: ReportType
    ) => {
        if (!reportDetails) {
            return;
        }

        const updatedReportDetails: IReportDetails = {
            ...reportDetails,
            reportTypeId: reportType,
            reportDateType: reportDateType,
        };

        setReportDetails(updatedReportDetails);
        setDateTypeChanged(!dateTypeChanged);
    };

    useEffect(() => {
        if (!reportId) {
            navigate(AppRoutes.NotFound);
            return;
        }

        const parsedId = Number.parseInt(reportId);
        if (!parsedId) {
            navigate(AppRoutes.NotFound);
            return;
        }

        if (parsedReportId === parsedId) {
            return;
        }

        setParsedReportId(parsedId);
        setShowLoading(true);
    }, [navigate, parsedReportId, reportId]);

    useEffect(() => {
        const getReportDetails = async (parsedReportId: number) => {
            const reportDetailsResponse = await getReportById(parsedReportId);
            const reportDetails: IReportDetails =
                await reportDetailsResponse.json();

            setReportDetails(reportDetails);
            setFilters((oldState) => ({
                ...oldState,
                channels: reportDetails.channels,
            }));
            setIsReportDetailsLoaded(true);
        };

        if (parsedReportId !== undefined) {
            getReportDetails(parsedReportId);
        }
    }, [getReportById, parsedReportId]);

    useEffect(() => {
        const getReportItems = async (request: IGetReportItemsRequest) => {
            try {
                const reportsResponse = await getReportItemsById(request);
                const reportsData: IPagedResponse<IOvernightDataItem> =
                    await reportsResponse.json();

                reportsData.records.map((record) => {
                    record.startTime = formatDate(new Date(record.startTime));
                    return record;
                });

                setPaginatedReportsItems(reportsData.records);
                setTotalPages(Math.ceil(reportsData.recordsCount / pageSize));
            } finally {
                setShowLoading(false);
            }
        };

        if (isReportDetailsLoaded && parsedReportId !== undefined) {
            setShowLoading(true);

            const pagedRequest: IGetReportItemsRequest = {
                reportId: parsedReportId,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
                searchValue: filters.searchValue,
            };

            getReportItems(pagedRequest);
        }
    }, [
        currentPage,
        filters,
        ordersChanged,
        dateTypeChanged,
        getReportItemsById,
        pageSize,
        parsedReportId,
        isReportDetailsLoaded,
    ]);

    return (
        <div className={styles.container}>
            {reportDetails && (
                <>
                    <ReportDetailsHeader
                        report={reportDetails}
                        setNewReportDateType={handleSetReportDateType}
                    />
                    <ReportTableActions
                        filters={filters}
                        setFilters={setFilters}
                        reportId={reportDetails.id}
                        columns={reportDetails.columns}
                        setNewColumns={handleSetNewColumns}
                    />

                    {!emptyReportItems && !allColumnHidden && (
                        <>
                            <ReportTable
                                items={paginatedReportsItems}
                                reportDetails={reportDetails}
                                searchValue={filters.searchValue}
                                onSortOrderChange={handleSortOrderChange}
                            />

                            <PaginationWithPageSize
                                ref={paginationRef}
                                setCurrentPage={setCurrentPage}
                                setPageSize={setPageSize}
                                totalPageCount={totalPages}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                availablePageSizes={[10, 20, 30]}
                            />
                        </>
                    )}

                    {((emptyReportItems && emptyFilters) ||
                        allColumnHidden) && (
                        <EntityEmptyBox message="No reports details to show." />
                    )}
                    {emptyReportItems && !emptyFilters && <FilterEmptyBox />}
                </>
            )}
            <PageLoadingIndicator
                showLoading={showLoading}
                text="Loading Report Details..."
            />
        </div>
    );
};
