import { AuthProviderProps } from 'react-oidc-context';

export const oidcConfig = {
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_URL,
    response_type: 'code',
    scope: process.env.REACT_APP_AUTH_SCOPES,
    onSigninCallback: () => {
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
    },
} as AuthProviderProps;
