import { Panel } from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import { AcceptedCookiesPolicySessionItemKey } from '../../Constants';
import useApi from '../../api';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { AcceptedCookiesPolicyResponse } from '../../models/acceptedCookiesPolicyResponse';
import styles from './cookiesFooterCover.module.scss';

export const CookiesFooterCover = () => {
    const { acceptCookies, getAcceptedCookies } = useApi();
    const [sendAcceptCookies, setSendAcceptCookies] = useState(false);
    const { value: cookiesAccepted, setValue: setCookiesAccepted } =
        useSessionStorage(AcceptedCookiesPolicySessionItemKey, false);

    useEffect(() => {
        if (cookiesAccepted as boolean) return;

        const fetchAcceptedCookies = async () => {
            const response = await getAcceptedCookies();
            const data: AcceptedCookiesPolicyResponse = await response.json();
            setCookiesAccepted(data.isCookiesPolicyAccepted);
        };

        fetchAcceptedCookies();
    }, [cookiesAccepted, getAcceptedCookies, setCookiesAccepted]);

    useEffect(() => {
        if (!sendAcceptCookies) return;

        const postAcceptCookies = async () => {
            const response = await acceptCookies();

            if (response.ok) {
                setCookiesAccepted(true);
            }
            setSendAcceptCookies(false);
        };
        postAcceptCookies();
    }, [sendAcceptCookies, acceptCookies, setCookiesAccepted]);

    const panelHeader = (
        <div
            className="ap-extra-template-container"
            style={{ display: 'flex' }}
        >
            <button
                aria-label="Close"
                className="ap-modal-header-icon ap-modal-header-close"
                onClick={() => setSendAcceptCookies(true)}
            >
                <span className="Appkit4-icon icon-close-outline height ap-font-medium"></span>
            </button>
        </div>
    );

    return (
        <>
            {!cookiesAccepted && (
                <div className={styles['ap-panel-with-extra-container']}>
                    <Panel title="Cookie policy" extra={panelHeader}>
                        TODO: Cookie policy goes here
                    </Panel>
                </div>
            )}
        </>
    );
};
