import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate, useLocation } from 'react-router-dom';
import { PageLoadingIndicator } from '../components/pageLoadingIndicator/pageLoadingIndicator';
import { AppRoutes } from '../Constants';

type AuthenticationWrapperProps = { children: JSX.Element };

export const AuthenticationWrapper: FC<AuthenticationWrapperProps> = (
    props: AuthenticationWrapperProps
) => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.isLoading) {
        return <PageLoadingIndicator showLoading={auth.isLoading} />;
    }

    if (auth.error) {
        return (
            <div aria-label="authetication-error">
                Authentication error: {auth.error.message}
            </div>
        );
    }

    if (!auth.isLoading && !auth.isAuthenticated) {
        return (
            <Navigate
                to={AppRoutes.LandingPage}
                replace
                state={{ returnRoute: location.pathname }}
            />
        );
    }

    return <>{props.children}</>;
};
