import { useContext } from 'react';
import { StaticDataContext } from '../contexts/StaticDataContext';

export default function useEnums() {
    const contextData = useContext(StaticDataContext);

    const getEnumName = (enumName: string, id: number) =>
        contextData ? contextData.enumDictionary[enumName][id - 1].name : '';

    return { enumDictionary: contextData?.enumDictionary, getEnumName };
}
