import { createContext } from 'react';
import { reactPlugin } from './AppInsights';

const AppInsightsContext = createContext(reactPlugin);

const AppInsightsContextProvider = ({ children }: any) => {
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

export { AppInsightsContext, AppInsightsContextProvider };
