import { Breadcrumb, BreadcrumbItem } from '@appkit4/react-components';
import { Link } from 'react-router-dom';
import styles from './breadcrumbElement.module.scss';
interface BreadcrumbProps {
    items: Array<BreadcrumbItemProps>;
}

interface BreadcrumbItemProps {
    url: string;
    name: string;
}

export const BreadcrumbElement = (props: BreadcrumbProps) => {
    return (
        <Breadcrumb className={styles.breadcrumbContainer}>
            {props.items.map((item, index) => (
                <BreadcrumbItem key={index}>
                    <Link to={item.url}>{item.name}</Link>
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};
