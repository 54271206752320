import { PaginationRef } from '@appkit4/react-components';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import useApi from '../../api';
import { IGetReportsRequest } from '../../api/models/getReportsRequest';
import { IPagedResponse } from '../../api/models/pagedResponse';
import { EntityEmptyBox } from '../entityEmptyBox/entityEmptyBox';
import { FilterEmptyBox } from '../filterEmptyBox/filterEmptyBox';
import { PageLoadingIndicator } from '../pageLoadingIndicator/pageLoadingIndicator';
import { PaginationWithPageSize } from '../paginationWithPageSize/paginationWithPageSize';
import { ReportPanel } from './components/reportsGrid/reportPanel/reportPanel';
import { ReportsGrid } from './components/reportsGrid/reportsGrid';
import { ReportsHeader } from './components/reportsHeader/reportsHeader';
import { IReport } from './models/report';
import { ReportFilters } from './models/reportFilters';
import styles from './reportsPage.module.scss';

export const ReportsPage = () => {
    const { getReports } = useApi();

    const paginationRef = useRef() as MutableRefObject<PaginationRef>;

    const [showLoading, setShowLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [paginatedReports, setPaginatedReports] = useState<IReport[]>([]);
    const [reportFilter, setReportFilter] = useState<ReportFilters>({
        reportDatatypeIdArray: [],
        reportTypeIdArray: [],
        onlyFavourite: false,
        searchValue: '',
    });

    const [favouriteChanged, setFavouriteChanged] = useState<boolean>(false);

    const onFavouriteChanged = (isFavourite: boolean) => {
        if (reportFilter.onlyFavourite && !isFavourite) {
            setFavouriteChanged(!favouriteChanged);
        }
    };

    const emptyFilters =
        reportFilter.reportDatatypeIdArray.length === 0 &&
        reportFilter.reportTypeIdArray.length === 0 &&
        reportFilter.startDate === undefined &&
        reportFilter.endDate === undefined &&
        reportFilter.searchValue === '';

    const emptyReports = paginatedReports.length === 0;

    useEffect(() => {
        setShowLoading(true);

        const getReportPage = async (request: IGetReportsRequest) => {
            try {
                const reportsResponse = await getReports(request);
                const reportsData: IPagedResponse<IReport> =
                    await reportsResponse.json();

                reportsData.records.map((record) => {
                    record.createdDate = new Date(record.createdDate);
                    record.lastUpdatedDate = new Date(record.lastUpdatedDate);
                });

                setPaginatedReports(reportsData.records);
                setTotalPages(Math.ceil(reportsData.recordsCount / pageSize));
            } finally {
                setShowLoading(false);
            }
        };

        const pagedRequest: IGetReportsRequest = {
            skip: (currentPage - 1) * pageSize,
            take: pageSize,
            onlyFavourite: reportFilter.onlyFavourite,
            reportDatatypeIds: reportFilter.reportDatatypeIdArray,
            reportTypeIds: reportFilter.reportTypeIdArray,
            startDate: reportFilter.startDate,
            endDate: reportFilter.endDate,
            searchValue: reportFilter.searchValue,
        };
        getReportPage(pagedRequest);
    }, [currentPage, pageSize, getReports, reportFilter, favouriteChanged]);

    useEffect(() => {
        if (paginationRef && paginationRef.current) {
            paginationRef.current.setPageNumber(currentPage);
        }
    }, [totalPages]);

    return (
        <div className={styles.container}>
            <ReportsHeader
                filters={reportFilter}
                setFilters={setReportFilter}
            />

            {!emptyReports && (
                <>
                    <ReportsGrid isSingleReport={paginatedReports.length === 1}>
                        <>
                            {paginatedReports.map((report: IReport) => (
                                <ReportPanel
                                    key={report.id}
                                    className="report"
                                    report={report}
                                    filters={reportFilter}
                                    onFavouriteChanged={onFavouriteChanged}
                                />
                            ))}
                        </>
                    </ReportsGrid>

                    <PaginationWithPageSize
                        ref={paginationRef}
                        setCurrentPage={setCurrentPage}
                        setPageSize={setPageSize}
                        totalPageCount={totalPages}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        availablePageSizes={[6, 12, 18]}
                    />
                </>
            )}

            {emptyReports && emptyFilters && (
                <EntityEmptyBox message="No reports have been created." />
            )}
            {emptyReports && !emptyFilters && <FilterEmptyBox />}

            <PageLoadingIndicator
                showLoading={showLoading}
                text="Loading Reports..."
            />
        </div>
    );
};
