import {
    Button,
    Checkbox,
    CheckboxGroup,
    CheckboxValueType,
    Panel,
} from '@appkit4/react-components';
import { useEffect, useRef, useState } from 'react';
import styles from './buttonDropdown.module.scss';

export type IButtonDropdownValue = {
    value: string;
    name: string;
    isChecked: boolean;
};

type ButtonDropdownProps = {
    buttonKind: string;
    buttonText: string;
    ariaLabel: string;
    dropdownValues: IButtonDropdownValue[];
    onValueChange: (values: string[]) => void;
};

export const ButtonDropdown = (props: ButtonDropdownProps) => {
    const panelAriaLabel = props.ariaLabel + ' values';
    const buttonRef = useRef<HTMLButtonElement>(null);
    const panelRef = useRef<HTMLDivElement>(null);
    const [isPanelDisplayed, setIsPanelDisplayed] = useState(false);

    const [selectedValues, setSelectedValues] = useState<string[]>(
        props.dropdownValues.filter((v) => v.isChecked).map((c) => c.value)
    );

    const handleCheckboxChange = (
        values: CheckboxValueType[],
        event: React.SyntheticEvent<Element, Event>
    ) => {
        const selectedValues: string[] = values as string[];
        setSelectedValues(selectedValues);
        props.onValueChange(selectedValues);
    };

    const handleClickButton = () => {
        setIsPanelDisplayed(!isPanelDisplayed);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                buttonRef.current &&
                panelRef.current &&
                !buttonRef.current.contains(event.target as Node) &&
                !panelRef.current.contains(event.target as Node)
            ) {
                setIsPanelDisplayed(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [buttonRef, panelRef]);

    return (
        <>
            <Button
                aria-label={props.ariaLabel}
                className={styles.button}
                onClick={handleClickButton}
                kind={props.buttonKind}
                ref={buttonRef}
            >
                {props.buttonText}
            </Button>
            {isPanelDisplayed && (
                <Panel
                    aria-label={panelAriaLabel}
                    ref={panelRef}
                    className={styles.panel}
                    style={{
                        top: buttonRef.current
                            ? buttonRef.current.offsetTop +
                              buttonRef.current.offsetHeight +
                              4
                            : undefined,
                    }}
                >
                    <CheckboxGroup
                        value={selectedValues}
                        onChange={handleCheckboxChange}
                    >
                        {props.dropdownValues.map((dropdownValue, index) => (
                            <Checkbox
                                key={index}
                                value={dropdownValue.value}
                                className={styles.checkbox}
                            >
                                {dropdownValue.name}
                            </Checkbox>
                        ))}
                    </CheckboxGroup>
                </Panel>
            )}
        </>
    );
};
