import { Select, Switch } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import styles from './reportingPeriod.module.scss';

type TimeRangeProps = {
    isAllDay: boolean;
    fromHour: number | undefined;
    toHour: number | undefined;
    setTimeRange: (
        from: number | undefined,
        to: number | undefined,
        isAllDay: boolean
    ) => void;
    shouldDisplayError: boolean;
};

export enum TimeRangeValues {
    Minimum = 2,
    Maximum = 26,
}

export const TimeRange = (props: TimeRangeProps) => {
    const timeValues = Array.from({ length: 25 }, (_, i) => {
        const hour: number = i + TimeRangeValues.Minimum;
        const label = `${hour}:00`;
        return { value: hour, label };
    });

    const handleFromHour = (value: SelectValue) => {
        props.setTimeRange(value as number, props.toHour, props.isAllDay);
    };

    const handleToHour = (value: SelectValue) => {
        props.setTimeRange(props.fromHour, value as number, props.isAllDay);
    };

    const handleIsAllDay = (newValue: boolean) => {
        props.setTimeRange(props.fromHour, props.toHour, newValue);
    };

    const isEarlierThan = (date1: number, date2: number): boolean => {
        return date1 < date2;
    };

    const isEarlierOrEqualThan = (date1: number, date2: number): boolean => {
        return date1 <= date2;
    };

    const shouldDisplayFromHourError: boolean =
        !props.isAllDay &&
        ((!props.fromHour && props.shouldDisplayError) ||
            (!!props.fromHour &&
                !!props.toHour &&
                isEarlierThan(props.toHour, props.fromHour)));

    const shouldDisplayToHourError: boolean =
        !props.isAllDay &&
        ((!props.toHour && props.shouldDisplayError) ||
            (!!props.toHour &&
                !!props.fromHour &&
                isEarlierOrEqualThan(props.toHour, props.fromHour)));

    return (
        <div className={styles.filterRow}>
            <span>From</span>
            <Select
                disabled={props.isAllDay}
                placeholder="Time"
                data={timeValues}
                className={styles.timeSelect}
                value={props.fromHour}
                onSelect={handleFromHour}
                error={shouldDisplayFromHourError}
            />
            <span>To</span>
            <Select
                disabled={props.isAllDay}
                placeholder="Time"
                data={timeValues}
                className={styles.timeSelect}
                value={props.toHour}
                onSelect={handleToHour}
                error={shouldDisplayToHourError}
            />
            <Switch
                onChange={handleIsAllDay}
                className={styles.switch}
                checked={props.isAllDay}
            >
                All day
            </Switch>
        </div>
    );
};
