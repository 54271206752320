import { useAuth } from 'react-oidc-context';
import useApi from '../api';

export const useLogout = () => {
    const auth = useAuth();
    const api = useApi();

    const logout = async () => {
        const idToken = auth.user?.id_token;
        window.sessionStorage.clear();
        api.logOutUser();
        await auth.signoutRedirect({ id_token_hint: idToken });
    };

    return logout;
};
