import { CalendarPicker } from '@appkit4/react-components';
import { useState } from 'react';
import { IStaticReportDate } from '../../models/reportDateType';
import styles from './reportingPeriod.module.scss';
import { TimeRange } from './timeRange';

type StaticFilterProps = {
    filters: IStaticReportDate;
    setFilters: (value: IStaticReportDate) => void;
    shouldDisplayError: boolean;
};

export const StaticFilter = (props: StaticFilterProps) => {
    const getDateValue = () => {
        if (props.filters.startDate && props.filters.endDate) {
            return [
                new Date(props.filters.startDate),
                new Date(props.filters.endDate),
            ];
        } else {
            return [];
        }
    };
    const [dateValue, setDateFilters] = useState<Array<Date>>(getDateValue);

    const handleDatePickerChange = (
        dateArray: Array<Date>,
        isValid: boolean
    ) => {
        const isDataEmpty = () =>
            !isValid || !dateArray || (!dateArray[0] && !dateArray[1]);
        if (isDataEmpty()) return;

        setDateFilters(dateArray);

        const dateFrom = getDateOnly(dateArray[0]);
        const dateTo = getDateOnly(dateArray[1]);

        const updatedFilters: IStaticReportDate = {
            ...props.filters,
            startDate: dateFrom,
            endDate: dateTo,
        };
        props.setFilters(updatedFilters);
    };

    const getDateOnly = (date: Date) => {
        const dateObject =
            date &&
            new Date(
                Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
            );

        return dateObject;
    };

    const handleSetTimeRange = (
        from: number | undefined,
        to: number | undefined,
        isAllDay: boolean
    ) => {
        if (!props.filters) {
            return;
        }

        const updatedFilters: IStaticReportDate = {
            ...props.filters,
            fromHour: from ? from : props.filters.fromHour,
            toHour: to ? to : props.filters.toHour,
            isAllDay: isAllDay,
        };

        props.setFilters(updatedFilters);
    };

    return (
        <div className={styles.filtersContainer}>
            <CalendarPicker
                className={styles.dateRangePicker}
                selectRange={true}
                placeholder="dd/mm/yyyy to dd/mm/yyyy"
                format="DD/MM/YYYY"
                fieldTitle="Date range"
                onChange={handleDatePickerChange}
                value={dateValue}
                useCustomValidation
                error={
                    !props.filters.startDate &&
                    !props.filters.endDate &&
                    props.shouldDisplayError
                }
            />
            <TimeRange
                fromHour={props.filters.fromHour}
                toHour={props.filters.toHour}
                isAllDay={props.filters.isAllDay}
                setTimeRange={handleSetTimeRange}
                shouldDisplayError={props.shouldDisplayError}
            />
        </div>
    );
};
