import { AcceptableUserRoles } from '../Constants';

export const userIsInRole = (
    userRole: AcceptableUserRoles | null | undefined
) => {
    if (!userRole) {
        return false;
    }
    if (Object.values(AcceptableUserRoles).includes(userRole)) {
        return true;
    }
    return false;
};
