import {
    Button,
    CalendarPicker,
    Combobox,
    Search,
    Tab,
    Tabs,
} from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../../../Constants';
import useEnums from '../../../../hooks/useEnums';
import { EnumNames } from '../../../../models/enumNames';
import { ReportFilters } from '../../models/reportFilters';
import { ReportTabs } from '../../models/reportTabs.enum';
import styles from './reportsHeader.module.scss';

type ReportsHeaderProps = {
    filters: ReportFilters;
    setFilters: Function;
};

export const ReportsHeader: FC<ReportsHeaderProps> = (
    props: ReportsHeaderProps
) => {
    const { enumDictionary } = useEnums();
    const navigate = useNavigate();
    const [activeTabIndex, setActiveTabIndex] = useState(ReportTabs.AllReports);
    const [searchValue, setSearchValue] = useState('');
    const [filterValue, setFilterValue] = useState<SelectValue>();
    const [dateValue, setDateValue] = useState<Array<Date>>();

    const onTabChange = (i: number) => {
        setActiveTabIndex(i);
        props.setFilters({
            ...props.filters,
            onlyFavourite: i === ReportTabs.Favourite,
        });
    };

    useEffect(() => {
        if (props.filters.searchValue === searchValue) {
            return;
        }

        const debounceTimeout = setTimeout(() => {
            props.setFilters({
                ...props.filters,
                searchValue: searchValue.length > 2 ? searchValue : '',
            });
        }, 300);

        return () => clearTimeout(debounceTimeout);
    }, [searchValue]);

    const handleCustomiseReport = () => {
        navigate(AppRoutes.CustomiseReport);
    };

    const handleDatePickerChange = (
        dateArray: Array<Date>,
        isValid: boolean
    ) => {
        const isDataEmpty = () =>
            !isValid || !dateArray || !dateArray[0] || !dateArray[1];

        setDateValue(dateArray);

        props.setFilters({
            ...props.filters,
            startDate: isDataEmpty() ? undefined : dateArray[0],
            endDate: isDataEmpty() ? undefined : dateArray[1],
        });
    };

    const handleFilterSelect = (values: SelectValue) => {
        const value = values as string[];
        const reportTypeArray = value
            .filter((type) => type.endsWith(EnumNames.ReportType))
            .map((x) => parseInt(x.split(' ')[0]));
        const reportDataTypeArray = value
            .filter((type) => type.endsWith(EnumNames.ReportDataType))
            .map((x) => parseInt(x.split(' ')[0]));

        setFilterValue(value);

        props.setFilters({
            ...props.filters,
            reportTypeIdArray: reportTypeArray,
            reportDatatypeIdArray: reportDataTypeArray,
        });
    };

    const dropdownData = [
        {
            desc: 'Report type',
            type: 'group',
            children: enumDictionary
                ? enumDictionary[EnumNames.ReportType].map((report) => ({
                      value: `${report.id} ${EnumNames.ReportType}`,
                      desc: report.name,
                  }))
                : [],
        },
        {
            desc: 'Data type',
            type: 'group',
            children: enumDictionary
                ? enumDictionary[EnumNames.ReportDataType].map((data) => ({
                      value: `${data.id} ${EnumNames.ReportDataType}`,
                      desc: data.name,
                  }))
                : [],
        },
    ];

    const handleOnClear = () => {
        setSearchValue('');
        setFilterValue([]);
        setDateValue([]);
        props.setFilters({
            reportTypeIdArray: [],
            reportDatatypeIdArray: [],
            startDate: undefined,
            endDate: undefined,
            onlyFavourite: props.filters.onlyFavourite,
            searchValue: '',
        });
    };

    return (
        <div className={styles.reportsHeaderContainer}>
            <div className={styles.reportsCustomizeBox}>
                <span className={styles.reportsCustomizeText}>Reports</span>
                <div>
                    <Button
                        aria-label="Customise a report"
                        className={styles.reportsCustomizeButton}
                        onClick={handleCustomiseReport}
                    >
                        Customise a report
                    </Button>
                </div>
            </div>
            <div className={styles.reportsTableButtonsContainer}>
                <Tabs
                    type="filled"
                    className={styles.tableTabs}
                    activeIndex={activeTabIndex}
                    onTabChange={onTabChange}
                >
                    <Tab aria-label="All reports" label="All reports"></Tab>
                    <Tab aria-label="Favourites" label="Favourites"></Tab>
                </Tabs>
                <div className={styles.tableButtonsBox}>
                    <Button
                        className={styles.clearButton}
                        onClick={handleOnClear}
                    >
                        Clear all filters
                    </Button>
                    <CalendarPicker
                        className={styles.datePicker}
                        selectRange={true}
                        placeholder="dd/mm/yyyy to dd/mm/yyyy"
                        format="DD/MM/YYYY"
                        fieldTitle="Last updated date"
                        onChange={handleDatePickerChange}
                        value={dateValue}
                    />
                    <Combobox
                        aria-label="Filter by"
                        placeholder="Filter by"
                        className={styles.selectButton}
                        data={dropdownData}
                        multiple={true}
                        valueKey={'value'}
                        labelKey={'desc'}
                        showSelectAll={false}
                        dropdownMatchWidth={true}
                        onSelect={handleFilterSelect}
                        value={filterValue}
                    />
                    <Search
                        ariaLabel="Search for a report"
                        placeholder="Search for a report"
                        className={styles.searchButton}
                        onChange={setSearchValue}
                        searchValue={searchValue}
                    ></Search>
                </div>
            </div>
        </div>
    );
};
