import {
    IDynamicReportDate,
    IReportDateType,
    IStaticReportDate,
} from '../../models/reportDateType';

export default function filterValidator() {
    const validateDynamic = (filter: IDynamicReportDate) => {
        return (
            filter && filter.dynamicReportPeriodTypeId && validateTime(filter)
        );
    };

    const validateStatic = (filter: IStaticReportDate) => {
        return (
            filter && filter.startDate && filter.endDate && validateTime(filter)
        );
    };

    const validateTime = (filter: IReportDateType) => {
        return (
            filter.isAllDay ||
            (filter.fromHour &&
                filter.toHour &&
                filter.toHour > filter.fromHour)
        );
    };

    return {
        validateStatic,
        validateDynamic,
    };
}
