import { ItemDataType, Select, Switch } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import useEnums from '../../../../hooks/useEnums';
import { EnumNames } from '../../../../models/enumNames';
import { IDynamicReportDate } from '../../models/reportDateType';
import styles from './reportingPeriod.module.scss';
import { TimeRange } from './timeRange';

type DynamicFilterProps = {
    filters: IDynamicReportDate;
    setFilters: (value: IDynamicReportDate) => void;
    shouldDisplayError: boolean;
};

export const DynamicFilter = (props: DynamicFilterProps) => {
    const { enumDictionary } = useEnums();

    const dataTypeData: ItemDataType[] = enumDictionary
        ? enumDictionary[EnumNames.DynamicReportPeriodType].map((type) => ({
              value: type.id,
              label: type.name,
          }))
        : [];

    const handleDynamicReportPeriodType = (value: SelectValue) => {
        const updatedFilters: IDynamicReportDate = {
            ...props.filters,
            dynamicReportPeriodTypeId: value as number,
        };

        props.setFilters(updatedFilters);
    };

    const handleAreNotificationsOn = (value: boolean) => {
        const updatedFilters: IDynamicReportDate = {
            ...props.filters,
            areNotificationsOn: value,
        };

        props.setFilters(updatedFilters);
    };

    const handleSetTimeRange = (
        from: number | undefined,
        to: number | undefined,
        isAllDay: boolean
    ) => {
        if (!props.filters) {
            return;
        }

        const updatedFilters: IDynamicReportDate = {
            ...props.filters,
            fromHour: from ? from : props.filters.fromHour,
            toHour: to ? to : props.filters.toHour,
            isAllDay: isAllDay,
        };
        props.setFilters(updatedFilters);
    };

    return (
        <div className={styles.filtersContainer}>
            <div className={styles.filterRow}>
                <span>Report from</span>
                <Select
                    data={dataTypeData}
                    placeholder={'Period'}
                    className={styles.dynamicDateSelect}
                    value={props.filters.dynamicReportPeriodTypeId}
                    onSelect={handleDynamicReportPeriodType}
                    error={
                        !props.filters.dynamicReportPeriodTypeId &&
                        props.shouldDisplayError
                    }
                />
                <span>ago</span>
            </div>
            <TimeRange
                fromHour={props.filters.fromHour}
                toHour={props.filters.toHour}
                isAllDay={props.filters.isAllDay ?? false}
                setTimeRange={handleSetTimeRange}
                shouldDisplayError={props.shouldDisplayError}
            />
            <div className={styles.filterRow}>
                <Switch
                    checked={props.filters.areNotificationsOn}
                    onChange={handleAreNotificationsOn}
                    className={styles.switch}
                >
                    I want to receive updates via email
                </Switch>
            </div>
        </div>
    );
};
