import { Column, Loading, Table } from '@appkit4/react-components';
import { useState } from 'react';
import useApi from '../../../../api';
import { IUpdateReportOrderRequest } from '../../../../api/models/updateReportOrderRequest';
import { HighlightPartiallyText } from '../../../highlightPartiallyText/highlightPartiallyText';
import { IOvernightDataItem } from '../../models/overnightDataItem';
import { PhaseNumber } from '../../models/phaseNumber.enum';
import { IReportDetails } from '../../models/reportDetails';
import { ReportItemsOrder } from '../../models/reportItemsOrder';
import styles from './reportTable.module.scss';

type ItemTableProps = {
    items: IOvernightDataItem[];
    reportDetails: IReportDetails;
    searchValue: string;
    onSortOrderChange(order: ReportItemsOrder): void;
};

const firstCharToLowerCase = (text: string) =>
    text.charAt(0).toLowerCase() + text.slice(1);

const firstCharToUpperCase = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);

export const ReportTable = ({
    items,
    reportDetails,
    searchValue,
    onSortOrderChange,
}: ItemTableProps) => {
    const { updateOrderReport } = useApi();
    const [showLoading, setShowLoading] = useState(false);

    const getPhaseNumber = (order: ReportItemsOrder): PhaseNumber => {
        if (sortKey !== undefined) {
            return order.isOrderDescending
                ? PhaseNumber.Descending
                : PhaseNumber.Ascending;
        }

        return PhaseNumber.NoOrder;
    };

    const getIsOrderDescending = (sortingPhase: PhaseNumber): boolean =>
        sortingPhase === PhaseNumber.Descending;

    const sortKey = reportDetails.sortKey
        ? firstCharToLowerCase(reportDetails.sortKey)
        : undefined;
    const sortPhase = getPhaseNumber({
        sortKey: reportDetails.sortKey,
        isOrderDescending: reportDetails.isOrderDescending,
    });

    const handleOnSort = async (sortingKey: string, sortingPhase: number) => {
        let request: IUpdateReportOrderRequest = {
            descendingOrder: false,
        };

        if (sortingPhase !== PhaseNumber.NoOrder) {
            request = {
                sortKey: firstCharToUpperCase(sortingKey),
                descendingOrder: getIsOrderDescending(sortingPhase),
            };
        }

        setShowLoading(true);
        try {
            const response = await updateOrderReport(request, reportDetails.id);
            const reportOrder: IUpdateReportOrderRequest =
                await response.json();

            onSortOrderChange({
                sortKey: reportOrder.sortKey ?? undefined,
                isOrderDescending: reportOrder.descendingOrder,
            });
        } finally {
            setShowLoading(false);
        }
    };

    const handleRowRender = (row: any, field: string) => {
        if (field === 'channelName' || field === 'programmeTitle') {
            return (
                <HighlightPartiallyText
                    text={row[field]}
                    highlightedValue={searchValue}
                />
            );
        }
        return row[field];
    };

    return (
        <div className={styles.container}>
            <Table
                originalData={[...items]}
                hasTitle
                striped
                disableDefaultSort={true}
                onSort={handleOnSort}
                sortActive={sortKey}
                sortPhase={sortPhase}
            >
                {reportDetails.columns
                    .filter((c) => c.isVisible)
                    .sort((c1, c2) => c1.order - c2.order)
                    .map((column, index) => {
                        const codeName = firstCharToLowerCase(column.codeName);
                        return (
                            <Column
                                key={index}
                                field={codeName}
                                sortKey={codeName}
                                renderCell={handleRowRender}
                            >
                                {column.friendlyName}
                            </Column>
                        );
                    })}
            </Table>
            {showLoading && (
                <Loading
                    aria-label="loading"
                    loadingType="circular"
                    compact="true"
                    indeterminate={true}
                    className={styles.loading}
                />
            )}
        </div>
    );
};
