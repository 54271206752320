import { useState, useEffect } from 'react';

export const useSessionStorage = (storageKey: string, fallbackState: any) => {
    const sessionValue = sessionStorage.getItem(storageKey);
    const [value, setValue] = useState(
        sessionValue ? JSON.parse(sessionValue) : fallbackState
    );

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);

    return { value, setValue };
};
