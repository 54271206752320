import { useContext } from 'react';
import { StaticDataContext } from '../contexts/StaticDataContext';

export default function useGroupedChannels() {
    const contextData = useContext(StaticDataContext);

    const groupedChannels = contextData?.groupedChannels ?? undefined;

    return { groupedChannels };
}
