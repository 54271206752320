export const enum AppRoutes {
    LandingPage = '/landing-page',
    ReportsPage = '/reports-page',
    HomePage = '/home-page',
    UnauthorizedPage = '/unauthorized',
    ReportDetails = '/report-details',
    CustomiseReport = '/customise-report',
    NotFound = '/not-found',
}

export const ApiUri = process.env.REACT_APP_API_BASE_URL + '/v1';
export const AuthorizationContextPath = '/auth/authorizationContext';
export const AcceptedTCPath = '/user/acceptedTC';
export const AcceptedCookiesPolicyPath = '/user/acceptedCookiesPolicy';
export const MinimumPixelSizeForTitle = 355;
export const UserTCSessionItemKey = 'userTCAcceptedVersion';
export const AcceptedCookiesPolicySessionItemKey = 'areCookiesAccepted';

export const TermsAndConditionsVersion = 1.0;

export const ToastGlobalConfig = {
    Duration: 5000,
    PositionGlobal: 'topHeader',
    ShowTimer: true,
};

export enum AcceptableUserRoles {
    Admin = 'Admin',
    Viewer = 'Viewer',
}
