import { Header } from '@appkit4/react-components';
import { Content } from './layout/content/content';
import { titleTemplate } from './layout/titleTemplate/titleTemplate';

export const UnauthorizedPage = () => {
    return (
        <>
            <Header
                type="transparent"
                titleTemplate={titleTemplate}
                style={{ minWidth: '0' }}
            />
            <Content>
                <h2>You are not authorized to view this resource.</h2>
            </Content>
        </>
    );
};
