import { ReportDataType } from '../../../models/reportDataType.enum';
import { ReportType } from '../../../models/reportType.enum';

export const DataTypeStyleDict: Map<ReportDataType, string> = new Map<
    ReportDataType,
    string
>([
    [ReportDataType.Overnight, 'ap-bg-tangerine ap-text-neutral-20'],
    [
        ReportDataType.FourScreenAsViewed,
        'ap-bg-yellow-lighter ap-text-neutral-20',
    ],
    [ReportDataType.Consolidated, 'ap-bg-yellow-light ap-text-neutral-20'],
    [ReportDataType.SVOD, 'ap-bg-orange-light ap-text-neutral-20'],
]);

export const ReportTypeStyleDict: Map<number, string> = new Map<number, string>(
    [
        [ReportType.Static, 'ap-bg-gray-light ap-text-neutral-20'],
        [ReportType.Dynamic, 'ap-bg-gray-dark ap-text-neutral-01'],
    ]
);
