import { Notification, Select, toaster } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRoutes, ToastGlobalConfig } from '../../../../../Constants';
import useApi from '../../../../../api';
import { IShareReportRequest } from '../../../../../api/models/shareReportRequest';
import useLogger from '../../../../../api/monitoring/useLogger';
import { useBlobDownloader } from '../../../../../hooks/useBlobDownloader';
import DuplicateModal from '../../../../duplicateModal/duplicateModal';
import { IReportDetails } from '../../../models/reportDetails';
import ExportModal from '../../exportModal/exportModal';
import { DeleteModal } from './deleteModal/deleteModal';
import { ReportActions } from './reportActions.enum';
import styles from './reportActionsButton.module.scss';
import { SendCopyModal } from './sendCopyModal/sendCopyModal';

export const ReportActionsButton = ({ report }: { report: IReportDetails }) => {
    const navigate = useNavigate();
    const logger = useLogger();
    const {
        updateFavouriteReport,
        duplicateReport,
        exportReportToCSV,
        shareReport,
        deleteReport,
    } = useApi();
    const { downloadBlob } = useBlobDownloader();
    const [isFavourite, setIsFavourite] = useState(report.isFavourite);
    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [showSendCopyModal, setShowSendCopyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleSelect = async (value: SelectValue) => {
        const selected = value as ReportActions;
        switch (selected) {
            case ReportActions.Favourite:
                await handleFavourite();
                break;
            case ReportActions.Edit:
                await handleEdit();
                break;
            case ReportActions.Export:
                setShowExportModal(true);
                break;
            case ReportActions.SendCopy:
                setShowSendCopyModal(true);
                break;
            case ReportActions.Duplicate:
                setShowDuplicateModal(true);
                break;
            case ReportActions.Delete:
                setShowDeleteModal(true);
                break;
            default:
                break;
        }
    };

    const handleFavourite = async () => {
        const notificationFavourite = (
            <Notification
                message={<div>Added to favourites</div>}
                status="success"
            ></Notification>
        );

        try {
            const response = await updateFavouriteReport(
                !isFavourite,
                report.id
            );
            const isReportFavourite = await response.json();
            setIsFavourite(isReportFavourite);
            if (isReportFavourite) {
                toaster.notify(notificationFavourite, {
                    position: ToastGlobalConfig.PositionGlobal,
                    showTimer: ToastGlobalConfig.ShowTimer,
                    duration: ToastGlobalConfig.Duration,
                });
            }
        } catch (error: any) {
            logger.trackException(error);
        }
    };

    const handleEdit = async () => {
        navigate(AppRoutes.CustomiseReport, {
            state: {
                id: report.id,
                name: report.name,
                description: report.description,
                dataTypeId: report.dataTypeId,
            },
        });
    };

    const handleExport = async () => {
        try {
            const response = await exportReportToCSV(report.id);
            const data = await response.blob();
            const date = new Date()
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, '_');

            downloadBlob(data, `${date}_${report.name}.csv`);

            setShowExportModal(false);
        } catch (error: any) {
            setShowExportModal(false);
            logger.trackException(error);
        }
    };

    const handleSendCopy = async (recipientsIds: number[]) => {
        let notificationMessage = 'Copy of report successfully sent';
        let notificationStatus = 'success';
        const notificationErrorMessage =
            'Error during sending copy of the report. Try again later.';
        const notificationErrorStatus = 'error';

        try {
            const request: IShareReportRequest = {
                recipientsIds: recipientsIds,
            };
            const response = await shareReport(report.id, request);

            if (!response.ok) {
                notificationMessage = notificationErrorMessage;
                notificationStatus = notificationErrorStatus;
            }
        } catch (error: any) {
            logger.trackException(error);
            notificationMessage = notificationErrorMessage;
            notificationStatus = notificationErrorStatus;
        } finally {
            toaster.notify(
                <Notification
                    message={<div>{notificationMessage}</div>}
                    status={notificationStatus}
                />,
                {
                    position: ToastGlobalConfig.PositionGlobal,
                    showTimer: ToastGlobalConfig.ShowTimer,
                    duration: ToastGlobalConfig.Duration,
                }
            );
            setShowSendCopyModal(false);
        }
    };

    const handleDuplicate = async () => {
        try {
            const response = await duplicateReport(report.id);
            const newReportId = await response.json();
            setShowDuplicateModal(false);
            navigate(`${AppRoutes.ReportDetails}/${newReportId}`);
        } catch (error: any) {
            setShowDuplicateModal(false);
            logger.trackException(error);
        }
    };

    const handleDelete = async () => {
        let notificationMessage = 'Report deleted';
        let notificationStatus = 'success';

        const response = await deleteReport(report.id);
        try {
            if (response.ok) {
                setShowDeleteModal(false);
                navigate(AppRoutes.ReportsPage);
                toaster.notify(
                    <Notification
                        message={<div>{notificationMessage}</div>}
                        status={notificationStatus}
                    />,
                    {
                        position: ToastGlobalConfig.PositionGlobal,
                        showTimer: ToastGlobalConfig.ShowTimer,
                        duration: ToastGlobalConfig.Duration,
                    }
                );
            }
        } catch (error: any) {
            setShowDeleteModal(false);
            logger.trackException(error);
        }
    };

    const availableActions = [
        {
            value: ReportActions.Favourite,
            label: isFavourite ? 'Unfavourite report' : 'Favourite report',
            iconName: isFavourite ? 'rating-fill' : 'star-outline',
        },
        {
            value: ReportActions.Edit,
            label: 'Edit report',
            iconName: 'edit-outline',
        },
        {
            value: ReportActions.Export,
            label: 'Export report (CSV)',
            iconName: 'download-outline',
        },
        {
            value: ReportActions.SendCopy,
            label: 'Send a copy of this report',
            iconName: 'share-outline',
        },
        {
            value: ReportActions.Duplicate,
            label: 'Duplicate report',
            iconName: 'duplicate-outline',
        },
        {
            value: ReportActions.Delete,
            label: 'Delete report',
            iconName: 'delete-outline',
        },
    ];

    return (
        <>
            <Select
                aria-label="Actions"
                data={availableActions}
                placeholder={'Actions'}
                className={styles.actionsButton}
                prefixTemplate={(item) => {
                    return (
                        item.iconName && (
                            <span
                                className={`Appkit4-icon icon-${item.iconName}`}
                            ></span>
                        )
                    );
                }}
                dropdownMatchWidth={true}
                onSelect={handleSelect}
            ></Select>
            {showDuplicateModal && (
                <DuplicateModal
                    reportName={report.name}
                    onCancel={() => setShowDuplicateModal(false)}
                    onDuplicate={handleDuplicate}
                />
            )}
            {showExportModal && (
                <ExportModal
                    reportName={report.name}
                    onCancel={() => setShowExportModal(false)}
                    onExport={handleExport}
                />
            )}
            {showSendCopyModal && (
                <SendCopyModal
                    reportName={report.name}
                    onCancel={() => setShowSendCopyModal(false)}
                    onSend={handleSendCopy}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    reportName={report.name}
                    onCancel={() => setShowDeleteModal(false)}
                    onDelete={handleDelete}
                />
            )}
        </>
    );
};
