import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai: ApplicationInsights = new ApplicationInsights({
    config: {
        connectionString:
            process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        enableAutoRouteTracking: true,
    },
});

if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
    ai.loadAppInsights();
}

export const appInsights = ai.appInsights;
export { reactPlugin };
