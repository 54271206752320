import { Button, Header } from '@appkit4/react-components';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { Content } from '../layout/content/content';
import { titleTemplate } from '../layout/titleTemplate/titleTemplate';
import styles from './landingPage.module.scss';

export const LandingPage = (): JSX.Element => {
    const auth = useAuth();
    const location = useLocation();

    const { setValue: setReturnRoute } = useSessionStorage('returnRoute', null);

    const handleLogIn = () => {
        const returnRoute = location.state?.returnRoute;
        if (returnRoute) {
            setReturnRoute(returnRoute);
        }

        auth.signinRedirect();
    };

    return (
        <>
            <Header
                type="transparent"
                titleTemplate={titleTemplate}
                style={{ minWidth: '0' }}
            />
            <Content>
                <div className={styles.landingPage}>
                    <div className={styles.landingContent}>
                        <div className={styles.heading}>Sign in</div>
                        <div className={styles.subheading}>
                            Use credentials given in README file
                        </div>
                        {!auth.isAuthenticated && (
                            <>
                                <Button
                                    aria-label="Log in"
                                    className={styles.loginBtn}
                                    kind="primary"
                                    onClick={handleLogIn}
                                >
                                    Log in
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Content>
        </>
    );
};
