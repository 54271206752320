import styles from './entityEmptyBox.module.scss';

export const EntityEmptyBox = (props: { message: string }) => {
    return (
        <div className={styles.entityEmptyBoxContainer}>
            <span className={`Appkit4-icon icon-news-report-outline`}></span>
            <div className={styles.emptyBoxText}>{props.message}</div>
        </div>
    );
};
