import classNames from 'classnames';
import { FC } from 'react';
import styles from './reportsGrid.module.scss';

type ReportsGridProps = {
    isSingleReport: boolean;
    children: JSX.Element;
};

export const ReportsGrid: FC<ReportsGridProps> = (props: ReportsGridProps) => {
    return (
        <div
            className={classNames(styles.container, {
                [styles.containerOneItem]: props.isSingleReport,
            })}
        >
            {props.children}
        </div>
    );
};
