import { Panel } from '@appkit4/react-components';
import { FC } from 'react';
import { HighlightPartiallyText } from '../../../../highlightPartiallyText/highlightPartiallyText';
import { IReport } from '../../../models/report';
import { ReportFilters } from '../../../models/reportFilters';
import TextWrapper from '../../textWrapper/textWrapper';
import styles from './reportPanel.module.scss';
import { ReportPanelFooter } from './reportPanelFooter';
import ReportPanelHeader from './reportPanelHeader';

type ReportPanelProps = {
    className: string;
    report: IReport;
    filters: ReportFilters;
    onFavouriteChanged(isFavourite: boolean): void;
};

export const ReportPanel: FC<ReportPanelProps> = ({
    report,
    filters,
    onFavouriteChanged,
}: ReportPanelProps) => {
    return (
        <Panel
            footer={
                <ReportPanelFooter
                    report={report}
                    onFavouriteChanged={onFavouriteChanged}
                />
            }
            extra={
                <ReportPanelHeader
                    report={report}
                    node={
                        <HighlightPartiallyText
                            text={report.name}
                            highlightedValue={filters.searchValue}
                        />
                    }
                />
            }
            className={styles.reportPanel}
        >
            <TextWrapper
                text={report.description}
                className={styles.description}
                node={
                    <HighlightPartiallyText
                        text={report.description}
                        highlightedValue={filters.searchValue}
                    />
                }
            />
        </Panel>
    );
};
