import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../api';
import { AcceptableUserRoles, AppRoutes } from '../../Constants';
import { UserRoleContext } from '../../contexts/UserRoleContext';
import { userIsInRole } from '../../helpers/userIsInRole';
import { PageLoadingIndicator } from '../pageLoadingIndicator/pageLoadingIndicator';

type ProtectedRedirectProps = { children: JSX.Element };

export const ProtectedRedirect: FC<ProtectedRedirectProps> = ({ children }) => {
    const [role, setRole] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const { getAuthorizationContext } = useApi();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRole = async () => {
            const authContextResponse = await getAuthorizationContext();
            if (authContextResponse.status === 403) {
                setIsLoading(false);
                navigate(AppRoutes.UnauthorizedPage);
                return;
            }

            const authContextData = await authContextResponse.json();

            if (
                !userIsInRole(authContextData.roleName as AcceptableUserRoles)
            ) {
                setIsLoading(false);
                navigate(AppRoutes.UnauthorizedPage);
                return;
            }

            setRole(authContextData.roleName);
            setIsLoading(false);
        };

        fetchRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return (
            <PageLoadingIndicator
                showLoading={isLoading}
                text="Fetching role..."
            />
        );
    }

    return (
        <UserRoleContext.Provider value={role}>
            {children}
        </UserRoleContext.Provider>
    );
};
